import type { Option } from "./case-types";

export const caseActionOptions: Option[] = [
  {
    title: "Waiting on Amazon",
    value: "WAITING_ON_AMAZON",
    color: "#FFCD4D",
  },
  {
    title: "Waiting on CG",
    value: "WAITING_ON_CG",
    color: "#FF820F",
  },
  {
    title: "Approved",
    value: "APPROVED",
    color: "#39D4A5",
  },
  {
    title: "Partially Approved",
    value: "PARTIALLY_APPROVED",
    color: "#318FFF",
  },
  {
    title: "Denied",
    value: "DENIED",
    color: "#E03741",
  },
  {
    title: "Closed w/o Resolution",
    value: "CLOSED_WITHOUT_RESOLUTION",
    color: "#FECDD6",
  },
  {
    title: "Ready for Invoicing",
    value: "READY_FOR_INVOICING",
    color: "#F155FF",
  },
  {
    title: "Invoiced",
    value: "INVOICED",
    color: "#7B3DFF",
  },
  {
    title: "Not Pursuing",
    value: "NOT_PURSUING",
    color: "#EAECF0",
  },
];
