import { ServerCaseFilters } from "./casesApi";

export const initialFilters: ServerCaseFilters = {
  [ServerCaseFilters.AmazonCaseId]: "",
  [ServerCaseFilters.CreatedAt]: "",
  [ServerCaseFilters.CurrentAction]: "",
  [ServerCaseFilters.CurrentCaseOwner]: "",
  [ServerCaseFilters.StoreName]: "",
  [ServerCaseFilters.VendorName]: "",
};
