import { TextField } from "@mui/material";
import { Field } from "formik";
import { type FC } from "react";
import { type ShortageWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";

const getField = createFieldFn<ShortageWorkPacket>();

export const CustomerReference: FC = () => (
  <FormFieldsGroup
    title="Customer Reference"
    withDivider
    id="customer-reference"
  >
    <Field
      name={getField("vendorName")}
      as={TextField}
      variant="outlined"
      placeholder="Vendor Name"
      size="small"
      label="Vendor Name"
      disabled />

    <Field
      name={getField("vendorId")}
      as={TextField}
      variant="outlined"
      placeholder="Enter Vendor ID"
      size="small"
      label="Vendor ID"
      disabled />

    <Field
      name={getField("storeName")}
      as={TextField}
      variant="outlined"
      placeholder="Enter Store Name"
      size="small"
      label="Store Name"
      disabled />

    <Field
      name={getField("storeId")}
      as={TextField}
      variant="outlined"
      placeholder="Enter Store ID"
      size="small"
      label="Store ID"
      disabled />

    <Field
      name={getField("marketplaceCountry")}
      as={TextField}
      variant="outlined"
      placeholder="Enter Marketplace country"
      size="small"
      label="Marketplace Country"
      disabled />
  </FormFieldsGroup>
);
