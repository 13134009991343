import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { FC } from "react";

interface Case {
  id: string;
}

const getCaseById = async ({
  queryKey: [, caseId],
}: QueryFunctionContext<["work-packet-cases", caseId: string, "details"]>): Promise<Case> => ({
  id: caseId,
});

export interface CaseDetailsProps {
  caseId: string;
}

export const CaseDetails: FC<CaseDetailsProps> = ({ caseId }) => {
  const query = useQuery({
    queryFn: getCaseById,
    queryKey: ["work-packet-cases", caseId, "details"],
  });

  if (query.isError) return (<span>Failed to fetch case.</span>);
  if (query.isPending) return (<span>Loading case...</span>);

  return (
    <div>
      <span>Details for case {query.data.id}</span>
    </div>
  );
};
