import { Box } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import {
  AutocompleteField,
  CheckboxField,
  MultiAutocompleteField,
  SelectField,
  StringDateRangePickerField,
} from "src/components/UI/Form";
import { WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { WorkPacketType } from "../../WorkPacketType";
import { WorkPacketView } from "../../WorkPacketView";
import { useWorkPacketsContext } from "../../WorkPacketsContext";
import { useUserNickname } from "../../useUserNickname";
import { useUserOptions } from "../../useUserOptions";
import { filterableWorkPacketActions, staticFilterOptions } from "../helpers/getStaticFilterOptions";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";
import { useAccountOptions } from "./hooks/useAccountOptions";
import { useChargebackSubtypes } from "./hooks/useChargebackSubtypes";
import { useVendorOptions } from "./hooks/userVendorOptions";

export const ChargebackFiltersForm: FC = () => {
  const { currentView, currentWorkPacketType } = useWorkPacketsContext();
  const { setFieldValue } = useFormikContext();
  const userNickname = useUserNickname();

  const accountOptions = useAccountOptions();
  const userOptions = useUserOptions();
  const vendorOptions = useVendorOptions();
  const chargebackSubtypes = useChargebackSubtypes();

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        },
        gap: 2,
        rowGap: 3,
      }}
    >
      <Field
        name={WorkPacketFilter.VendorName}
        component={AutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Vendor Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={AutocompleteField}
        label="Store Name"
        options={accountOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Store Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.PacketStage}
        component={SelectField}
        label="Packet Stage"
        options={staticFilterOptions[WorkPacketFilter.PacketStage]}
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(currentView, userNickname)}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disabled={currentView !== WorkPacketView.AllPackets}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={filterableWorkPacketActions[WorkPacketType.CHARGEBACKS]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamType}
        component={AutocompleteField}
        label="Recovery Stream Type"
        options={chargebackSubtypes?.recoveryStreamType ?? EMPTY_ARRAY}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Type"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamSubtype1}
        component={AutocompleteField}
        label="Recovery Stream Subtype 1"
        options={chargebackSubtypes?.recoveryStreamSubtype1 ?? EMPTY_ARRAY}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Subtype 1"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamSubtype2}
        component={AutocompleteField}
        label="Recovery Stream Subtype 2"
        options={chargebackSubtypes?.recoveryStreamSubtype2 ?? EMPTY_ARRAY}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Subtype 2"
        setFieldValue={setFieldValue}
      />

      <Box gridColumn={{ xs: "span 1", sm: "span 2" }}>
        <Field
          name={WorkPacketFilter.IssueId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          getOptionLabel={(option: any) => option.title}
          label="Chargeback Issue ID"
          setFieldValue={setFieldValue}
          disableSuggestions
        />
      </Box>

      <Box gridColumn={{ xs: "span 1", sm: "span 2" }} display="flex" flexDirection="row" alignItems="center">
        <Box flexGrow={1}>
          <Field
            name={WorkPacketFilter.ChargebackCreatedAt}
            component={StringDateRangePickerField}
            label="Chargeback Created At"
          />
        </Box>
        <Field type="checkbox" name={WorkPacketFilter.RedFlags} component={CheckboxField} label="Red flags" />
      </Box>

      <Box gridColumn={{ xs: "span 1", sm: "span 2" }}>
        <Field
          name={WorkPacketFilter.VcPoId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          label="VC PO ID"
          setFieldValue={setFieldValue}
          disableSuggestions
        />
      </Box>

      <Box gridColumn={{ xs: "span 1", sm: "span 2" }}>
        <Field
          name={WorkPacketFilter.AsinId}
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          label="Filter by ASIN"
          setFieldValue={setFieldValue}
          disableSuggestions
        />
      </Box>
    </Box>
  );
};
