export const encodeUrlParams = (
  paramsObject: Record<string, string | number | string[] | Record<string, unknown>>,
): URLSearchParams =>
  new URLSearchParams(
    Object.entries(paramsObject).flatMap(([key, val]) => {
      if (Array.isArray(val)) {
        return val.map(subvalue => [key, subvalue] as [string, string]);
      }
      if (typeof val === "object") {
        return [[key, JSON.stringify(val)] as [string, string]];
      }
      if (typeof val === "number") {
        return [[key, val.toString()] as [string, string]];
      }
      if (typeof val === "string") {
        return [[key, val] as [string, string]];
      }
      throw new Error(`Unexpected param - key: "${key}", type: "${typeof val}"`);
    }) satisfies [string, string][],
  );
