import { TextField } from "@mui/material";
import { Field } from "formik";
import { type FC } from "react";
import { type ChargebackWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";

const getField = createFieldFn<ChargebackWorkPacket>();

export const CustomerReference: FC = () => (
  <FormFieldsGroup title="Customer Reference" withDivider id="customer-reference">
    <Field
      name={getField("vendorName")}
      as={TextField}
      variant="outlined"
      placeholder="Vendor Name"
      size="small"
      label="Vendor Name"
      disabled
    />

    <Field
      name={getField("vendorId")}
      as={TextField}
      variant="outlined"
      placeholder="Enter Vendor ID"
      size="small"
      label="Vendor ID"
      disabled
    />

    <Field
      name={getField("vendorCode")}
      as={TextField}
      variant="outlined"
      placeholder="Enter Vendor Code"
      size="small"
      label="Vendor Code"
      disabled
    />

    <TextField
      value="Amazon"
      variant="outlined"
      placeholder="Enter Marketplace"
      size="small"
      label="Marketplace"
      disabled
    />

    <Field
      name={getField("storeName")}
      as={TextField}
      variant="outlined"
      placeholder="Enter Store Name"
      size="small"
      label="Store Name"
      disabled
    />

    <Field
      name={getField("storeId")}
      as={TextField}
      variant="outlined"
      placeholder="Enter Store ID"
      size="small"
      label="Store ID"
      disabled
    />
  </FormFieldsGroup>
);
