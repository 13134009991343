import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import toast from "react-hot-toast";
import { DatePickerField, SelectField } from "components/UI/Form";
import * as casesApi from "../api/casesApi";
import dayjs from "dayjs";
import * as Yup from "yup";
import { FiCopy } from "react-icons/fi";

import styles from "./index.module.css";
import { WorkPacketDisputesId, WorkPacketFilters } from "src/types/work-packets";

interface CreateCasePopupProps {
  open: boolean;
  onClose: (success?: boolean) => void;
  selectedWorkPacketsIds: string[];
  excludedWorkPacketsIds: string[];
  filters: WorkPacketFilters;
}

const validationSchema = Yup.object().shape({
  caseId: Yup.string().required("Case Id is required"),
  caseType: Yup.string().required("Case type is required"),
  caseCreatedAt: Yup.object().required("Case Created Date is required"),
});

export const CreateCasePopup: FC<CreateCasePopupProps> = ({
  open,
  onClose,
  selectedWorkPacketsIds,
  excludedWorkPacketsIds,
  filters,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDisputes, setLoadingDisputes] = useState<boolean>(false);
  const [workPacketDisputesInfo, setWorkPacketDisputesInfo] = useState<WorkPacketDisputesId | null>(null);
  const initialValues = {
    caseId: "",
    caseType: "",
    caseCreatedAt: null,
  };
  const handleSubmit = async (values: { caseId: string; caseType: string; caseCreatedAt: dayjs.Dayjs | null }) => {
    setLoading(true);
    casesApi
      .createCase({
        caseId: values.caseId,
        caseType: values.caseType,
        caseCreatedAt: values.caseCreatedAt ? values.caseCreatedAt.format("YYYY-MM-DD") : "",
        workPacketIds: selectedWorkPacketsIds,
        excludedWorkPacketIds: excludedWorkPacketsIds,
        filters,
      })
      .then(() => {
        toast.success(
          `Shortage ${
            values.caseType == "SHORTAGE_ESCALATION" ? "Escalation" : "Settlement"
          } case created successfully`,
        );
        onClose(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoadingDisputes(true);
    casesApi
      .getDisputesIdsAndVendorInfo({
        excludedWorkPackets: excludedWorkPacketsIds,
        filters: filters,
        workPacketIds: selectedWorkPacketsIds,
      })
      .then(data => setWorkPacketDisputesInfo(data))
      .catch(() => onClose(true))
      .finally(() => {
        setLoadingDisputes(false);
      });
  }, [selectedWorkPacketsIds, excludedWorkPacketsIds, filters, onClose]);

  if (loadingDisputes)
    return (
      <Dialog open={open} maxWidth="md" fullWidth>
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      </Dialog>
    );

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md">
      <DialogTitle>Attach a Case ID</DialogTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ setFieldValue, dirty, isValid }) => (
          <Form>
            <DialogContent>
              {workPacketDisputesInfo && (
                <Box paddingBottom={"1.5rem"} display={"flex"} flexDirection={"column"}>
                  <ol className={styles.logCaseSteps}>
                    <li>
                      Log into
                      <Button
                        className={styles.linkBtn}
                        href={"vendorcentral.amazon.com"}
                        target={"_blank"}
                        variant={"text"}
                      >
                        Amazon Vendor Central
                      </Button>
                      as Filling User: {workPacketDisputesInfo.manual_filing_user}
                    </li>
                    <li>
                      Go to{" "}
                      <Button
                        className={styles.linkBtn}
                        href={"https://vendorcentral.amazon.com/katalmonsapp/vendor/members/disputes/re/create"}
                        variant={"text"}
                        target={"_blank"}
                      >
                        Case Creation Page
                      </Button>
                    </li>
                    <li>Fill in the following dispute IDs:</li>
                  </ol>
                  <OutlinedInput
                    size={"small"}
                    value={workPacketDisputesInfo.invoice_ids.join(",")}
                    endAdornment={
                      <InputAdornment position={"end"}>
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(workPacketDisputesInfo.invoice_ids.join(","));
                            toast.success("Dispute IDs copied");
                          }}
                        >
                          <FiCopy />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
              )}
              <hr className={styles.hr} />
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                <Field
                  name={"caseId"}
                  label="Case ID"
                  as={TextField}
                  size="small"
                  variant="outlined"
                  placeholder="Enter Case ID"
                  setFieldValue={setFieldValue}
                />
                <Field
                  name={"caseCreatedAt"}
                  label="Case Created Date"
                  component={DatePickerField}
                  placeholder="Case Created Date"
                  setFieldValue={setFieldValue}
                />
                <Field
                  name={"caseType"}
                  label="Case Type"
                  component={SelectField}
                  placeholder="Select a type of case"
                  setFieldValue={setFieldValue}
                  options={[
                    { value: "SHORTAGE_ESCALATION", label: "Shortage Escalation" },
                    { value: "SHORTAGE_SETTLEMENT", label: "Shortage Settlement" },
                  ]}
                />
              </Box>
            </DialogContent>
            <DialogActions style={{ background: "transparent" }}>
              <Button variant="outlined" onClick={() => onClose()} disabled={loading}>
                Cancel {isValid}
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!dirty || !isValid || loading}
                sx={{ paddingX: "24px" }}
              >
                <span>Attach Case ID</span>
                {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
