import {
  StagefulAction,
  StagelessAction,
  StageName,
  WorkPacketFilter,
  type WorkPacketAction,
} from "src/types/work-packets";
import { WorkPacketType } from "../../WorkPacketType";
import {
  actionMappers,
  createActionsForWorkPacketType,
  createStagefulAction,
  createStagelessAction
} from "../../mappers";

const recoveryStreamOptions = [
  { value: "", label: "All" },
  { value: "INVENTORY_SHORTAGES", label: "Inventory shortages" },
  { value: "PRICE_CLAIMS", label: "Price Claims" },
  { value: "ACCRUALS", label: "Accruals" },
  { value: "CHARGEBACKS", label: "Chargebacks" },
];

const packetStageOptions = [
  { value: "", label: "All" },
  { value: "Identify", label: "Identify" },
  { value: "Trigger", label: "Trigger" },
  { value: "Escalate", label: "Escalate" },
  { value: "Settle", label: "Settle" },
];

const rawSettableActions: Record<WorkPacketType, WorkPacketAction[]> = {
  [WorkPacketType.CHARGEBACKS]: [
    createStagefulAction(StageName.First, StagefulAction.Identified),

    // chargebacks only have two stages
    createStagefulAction(StageName.First, StagefulAction.DisputeCreated),
    createStagefulAction(StageName.First, StagefulAction.DisputeInReview),
    createStagefulAction(StageName.First, StagefulAction.DisputeInvoiced),

    createStagefulAction(StageName.Second, StagefulAction.DisputeCreated),
    createStagefulAction(StageName.Second, StagefulAction.DisputeInReview),
    createStagefulAction(StageName.Second, StagefulAction.DisputeInvoiced),

    createStagelessAction(StagelessAction.Junk),
    createStagelessAction(StagelessAction.Duplicate),
    createStagelessAction(StagelessAction.Expired),
    createStagelessAction(StagelessAction.NotApplicable),
  ],

  [WorkPacketType.SHORTAGES]: [
    createStagefulAction(StageName.First, StagefulAction.Identified),

    // shortages only have one stage, and don't have Dispute In Review
    createStagefulAction(StageName.First, StagefulAction.DisputeCreated),
    createStagefulAction(StageName.First, StagefulAction.DisputeInvoiced),

    // shortages don't have Junk or Expired actions
    createStagelessAction(StagelessAction.Duplicate),
    createStagelessAction(StagelessAction.NotApplicable),
  ],

  [WorkPacketType.ACCRUALS]: [
    createStagefulAction(StageName.First, StagefulAction.Identified),

    // accruals can have many stages, we support up to three
    createStagefulAction(StageName.First, StagefulAction.DisputeCreated),
    createStagefulAction(StageName.First, StagefulAction.DisputeInReview),
    createStagefulAction(StageName.First, StagefulAction.DisputeInvoiced),

    createStagefulAction(StageName.Second, StagefulAction.DisputeCreated),
    createStagefulAction(StageName.Second, StagefulAction.DisputeInReview),
    createStagefulAction(StageName.Second, StagefulAction.DisputeInvoiced),

    createStagefulAction(StageName.Third, StagefulAction.DisputeCreated),
    createStagefulAction(StageName.Third, StagefulAction.DisputeInReview),
    createStagefulAction(StageName.Third, StagefulAction.DisputeInvoiced),

    createStagelessAction(StagelessAction.Junk),
    createStagelessAction(StagelessAction.Duplicate),
    createStagelessAction(StagelessAction.Expired),
    createStagelessAction(StagelessAction.NotApplicable),
  ],
};

function createSettableActionsMap(nonAdminOnly: boolean) {
  const workPacketTypes = Object.values(WorkPacketType);
  return Object.fromEntries(
    workPacketTypes.map(workPacketType => [
      workPacketType,
      rawSettableActions[workPacketType]
        .filter(action => !nonAdminOnly || !action.onlyAdminCanSet)
        .map(actionMappers[workPacketType]),
    ]),
  ) as Record<WorkPacketType, WorkPacketAction[]>;
}

/**
 * Used within the WorkPacketsGrid to filter out the actions that are editable
 */
export const settableWorkPacketActions: Record<WorkPacketType, WorkPacketAction[]> = createSettableActionsMap(true);
export const adminSettableWorkPacketActions: Record<WorkPacketType, WorkPacketAction[]> =
  createSettableActionsMap(false);

const disputeStatuses = ["", "Resolved", "Pending Amazon action", "Pending your action"];

const disputeStatusOptions = disputeStatuses.map(status => ({ value: status, title: status }));

export const staticFilterOptions = {
  [WorkPacketFilter.RecoveryStream]: recoveryStreamOptions,
  [WorkPacketFilter.PacketStage]: packetStageOptions,
  [WorkPacketFilter.DisputeStatus]: disputeStatusOptions,
};

function createFilterableActionsMap() {
  const workPacketTypes = Object.values(WorkPacketType);
  return Object.fromEntries(
    workPacketTypes.map(workPacketType => [workPacketType, createActionsForWorkPacketType(workPacketType)]),
  ) as Record<WorkPacketType, WorkPacketAction[]>;
}

export const filterableWorkPacketActions: Record<WorkPacketType, WorkPacketAction[]> = createFilterableActionsMap();
