import { TextField } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import usePermissions from "src/components/CustomHooks/usePermissions";
import { AutocompleteField } from "src/components/UI/Form";
import { WorkPacketOwner, type AccrualWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";
import { useUserOptions } from "../../../useUserOptions";
import {
  adminSettableWorkPacketActions,
  settableWorkPacketActions,
} from "../../../WorkPacketsGrid/helpers/getStaticFilterOptions";
import { useWorkPacketsContext } from "../../../WorkPacketsContext";
import { WorkPacketView } from "../../../WorkPacketView";

const getField = createFieldFn<AccrualWorkPacket>();

export const CustomerReference: FC = () => {
  const { isAdmin } = usePermissions();
  const { setFieldValue } = useFormikContext();
  const { currentWorkPacketType, currentView } = useWorkPacketsContext();
  const userOptions = useUserOptions();
  const actionOptions = isAdmin ? adminSettableWorkPacketActions : settableWorkPacketActions;

  return (
    <FormFieldsGroup title="Customer Reference" withDivider id="customer-reference">
      <Field
        name={getField("vendorName")}
        as={TextField}
        variant="outlined"
        size="small"
        label="Vendor Name"
        disabled
      />
      <Field name={getField("vendorId")} as={TextField} variant="outlined" size="small" label="Vendor ID" disabled />
      <Field name={getField("storeName")} as={TextField} variant="outlined" size="small" label="Store Name" disabled />
      <Field name={getField("storeId")} as={TextField} variant="outlined" size="small" label="Store ID" disabled />

      <Field
        name={getField("vcFilingUser")}
        as={TextField}
        variant="outlined"
        size="small"
        label="Manual Filing User"
        disabled
      />

      <Field
        name={getField("currentPacketOwner.id")}
        component={AutocompleteField}
        label="Current Packet Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disableClearable={!isAdmin}
      />

      <Field
        name={getField("createdAt")}
        as={TextField}
        variant="outlined"
        size="small"
        label="Created Date - OBAR"
        disabled
      />

      <Field
        name={getField("obarOption")}
        as={TextField}
        variant="outlined"
        size="small"
        label="OBAR Option"
        disabled
      />

      <Field
        name={getField("currentAction")}
        component={AutocompleteField}
        label="Current Action"
        options={actionOptions[currentWorkPacketType]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        disabled={currentView === WorkPacketView.NewPackets && !isAdmin}
        disableClearable
        workPacketType={currentWorkPacketType}
      />
    </FormFieldsGroup>
  );
};
