import React, { useState , useContext } from "react";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import AddAccount from "./CustomersCreate/AddAccounts.jsx";
import AddScopes from "./CustomersCreate/AddScopes.jsx";
import { DeleteAccounts } from "./Api/Accounts/Accounts.js";
import Badges from "../../../components/Badges/Badges.jsx";
import DeleteModal from "../../../components/Modals/Delete.jsx";
import toast from "react-hot-toast";
import { AppContext } from "src/AppContext";
import formatNumber from "../../../utils/formatNumber.js";
import usePermissions from "../../../components/CustomHooks/usePermissions.js";

const ViewAccountsTable = ({
  tableData,
  spocAll,
  ShippingData,
  getData,
  avcData,
  chargebackProcessorData,
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowIndexScopes, setSelectedRowIndexScopes] = useState(null);
  const [accountsData, setAccountsData] = useState([]);
  const [scopesData, setScopesData] = useState([]);
  const [accountID, setAccountID] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [showAddScopes, setShowAddScopes] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isFormClosed, setIsFormClosed] = useState(false);
  const [changesInScope, setChangesInScopes] = useState(false);
  const { hasPermission } = usePermissions();
  const handleShowEditAccounts = (index, data) => {
    setSelectedRowIndex((prevIndex) => (prevIndex === index ? null : index));
    setAccountsData(data);

    // Close the scopes form
    setSelectedRowIndexScopes(null);
  };

  const handleShowEditScopes = (index, data, id, value) => {
    setSelectedRowIndexScopes((prevIndex) => {
      if (changesInScope) {
        getData();
      }
      if (prevIndex === index) {
        setIsFormClosed(false);
        return null;
      } else {
        return index;
      }
    });
    setScopesData(data);
    setAccountID(id);

    // Close the accounts form
    setSelectedRowIndex(null);
  };

  const DeleteOneAccount = () => {
    DeleteAccounts(vendorID, accountID, setLoader, toast, getData);
  };

  // Function to handle showing the delete modal
  const handleShowDelete = (accountID, vendorID) => {
    setShowConfirmation(true);
    setAccountID(accountID);
    setVendorID(vendorID);
  };

  const mapScopes = (scope) => {
    const mapping = {
      price_claims: "PC",
      shortages: "SH",
      chargebacks: "CB",
      accruals: "ACC",
    };
    return mapping[scope] || "";
  };

  return (
    <div className="table-container">
      <Table>
        <thead>
          <tr>
            <th>Store ID</th>
            <th>Name</th>
            <th>Scopes - Activation Date</th>
            <th>GMV</th>
            <th>Tech User</th>
            <th>Data Pull Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                {`S${item?.ID}` || "N/A"}
                <td>{item?.NAME || "N/A"}</td>
                <td>
                  {item?.SCOPES && JSON.parse(item?.SCOPES).length > 0 ? (
                    JSON.parse(item?.SCOPES).map((obj, objIndex) => (
                      <React.Fragment key={objIndex}>
                        <tr>
                          <td>
                            <Badges
                              key={objIndex}
                              text={mapScopes(obj.scope_name)}
                            />
                          </td>
                          -
                          <td>
                            <p>{obj.activation_date}</p>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">
                        <span className="badge text-bg-secondary text-white">
                          No scopes found
                        </span>
                      </td>
                    </tr>
                  )}
                </td>
                <td>{item?.GMV ? formatNumber(Number(item.GMV)) : "N/A"}</td>
                <td>{item?.DEFAULT_AVC_USER || "N/A"}</td>
                <td>{item?.AVC_DATA_PULL_STATUS || "N/A"}</td>
                <td>
                  <ButtonGroup>
                    <Button
                      variant="warning"
                      style={{ marginRight: "10px", borderRadius: "5px" }}
                      onClick={() => handleShowEditAccounts(index, item)}
                    >
                      View Store
                    </Button>
                    <Button
                      variant="warning"
                      style={{ marginRight: "10px", borderRadius: "5px" }}
                      onClick={() => {
                        handleShowEditScopes(index, item.scopes, item.ID, {
                          open: true,
                        });
                      }}
                    >
                      {item.SCOPES == [] ? "Add Scopes" : "View Scopes"}
                    </Button>
                    {hasPermission(['admin:*', 'user:client_config:write:*']) && (
                    <Button
                      variant="danger"
                      style={{ borderRadius: "5px" }}
                      onClick={() => handleShowDelete(item.ID, item.VENDOR_ID)}
                    >
                      Delete
                    </Button>
                    )}
                  </ButtonGroup>
                </td>
              </tr>
              {selectedRowIndex === index && (
                <tr>
                  <td colSpan="10">
                    <AddAccount
                      tableDataEdit={accountsData}
                      avcData={avcData}
                      getData={getData}
                      spocAll={spocAll}
                      mode="edit"
                      chargebackProcessorData={chargebackProcessorData}
                      ShippingData={ShippingData}
                    />
                  </td>
                </tr>
              )}
              {selectedRowIndexScopes === index && (
                <tr>
                  <td colSpan="10">
                    <AddScopes
                      setChangesInScopes={setChangesInScopes}
                      accountID={accountID}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      {showConfirmation && (
        <DeleteModal
          Delete={DeleteOneAccount}
          loader={loader}
          showDeleteModal={showConfirmation}
          setShowDeleteModal={setShowConfirmation}
        />
      )}
    </div>
  );
};

export default ViewAccountsTable;
