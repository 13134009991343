import { type WorkPacket, type WorkPacketTypeMap } from "src/types/work-packets";
import type { ArrayValues, StringKeyOf } from "type-fest";
import { WorkPacketType } from "../WorkPacketType";
import { type SortOption } from "./workPacketsAPI";

type ArrayIndexSortKeyMappings<T extends unknown[]> = Partial<Record<StringKeyOf<ArrayValues<T>>, string>>;

type WorkPacketArrayIndexSortKeyMapping<T extends WorkPacketType> = {
  [K in keyof WorkPacketTypeMap[T]]?: WorkPacketTypeMap[T][K] extends unknown[]
    ? ArrayIndexSortKeyMappings<WorkPacketTypeMap[T][K]>
    : never;
};

type WorkPacketArrayIndexSortKeyMappings = {
  [T in WorkPacketType]?: WorkPacketArrayIndexSortKeyMapping<T>;
};

const workPacketArrayIndexSortKeyMappings: WorkPacketArrayIndexSortKeyMappings = {
  [WorkPacketType.ACCRUALS]: {
    disputes: {
      disputeAmount: "disputeAmount",
      disputeDate: "disputeCreatedAt",
      disputeStatus: "disputeStatus",
      approvedAmount: "disputeApprovedAmount",
      disputeResolutionDate: "disputeResolutionDate",
      paidAmount: "disputePaidAmount",
    },
  },
};

const indexedPathRegex = /^(\w+)\[(\d+)\]\.(\w+)$/;

type SimpleMappings = {
  [T in WorkPacketType]?: Partial<Record<keyof WorkPacketTypeMap[T], string>>;
}

const simpleMappings: SimpleMappings = {
  [WorkPacketType.CHARGEBACKS]: {
    firstDisputeCreatedAt: "disputeCreatedAt",
    secondDisputeCreatedAt: "disputeCreatedAt",
  }
}

function mapSimpleSortOption(originalSortOption: SortOption, workPacketType: WorkPacketType): SortOption {
  const mappings = simpleMappings[workPacketType];
  if (!mappings) return originalSortOption;

  const mapping = mappings[originalSortOption.key as keyof WorkPacketTypeMap[WorkPacketType]];
  if (!mapping) return originalSortOption;

  const { direction } = originalSortOption;
  return { key: mapping, direction };
}

/**
 * The user may choose to sort on a property of a work packet that is inside an array.
 * The sort key used locally looks like `property[index].subproperty`, but the server expects a sort key in the form of `sortKey_index`.
 * This function resolves the `property` and `subproperty` combination into a `sortKey` and formats it as expected by the server.
 * If the incoming `originalSortOption` does not have an index access, it is returned unchanged.
 */
export function computeSortOption(
  originalSortOption: SortOption,
  workPacketType: WorkPacketType,
): SortOption | undefined {
  const regexResult = indexedPathRegex.exec(originalSortOption.key);
  if (!regexResult) return mapSimpleSortOption(originalSortOption, workPacketType);

  const { key, direction } = originalSortOption;
  const [_match, property, index, subproperty] = regexResult;
  const mappingsForWorkPacketType = workPacketArrayIndexSortKeyMappings[workPacketType];
  if (!mappingsForWorkPacketType) {
    console.error(
      `Sorting with indexed path "${key}", but no sort key mapping found for packet type "${workPacketType}"`,
    );
    return;
  }

  const mappingsForPropertyName = mappingsForWorkPacketType[property as keyof WorkPacket];
  if (!mappingsForPropertyName) {
    console.error(`Sorting with indexed path "${key}", but no sort key mapping found for property "${property}"`);
    return;
  }

  const mappingForSubpropertyName = mappingsForPropertyName[subproperty as keyof typeof mappingsForPropertyName];
  if (!mappingForSubpropertyName) {
    console.error(`Sorting with indexed path "${key}", but no sort key mapping found for subproperty "${subproperty}"`);
    return;
  }

  return { key: `${mappingForSubpropertyName}_${index}`, direction };
}
