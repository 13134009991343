import { type GridRenderCellParams, type GridRenderEditCellParams } from "@mui/x-data-grid";
import { type GridBaseColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { type ComponentProps, type FC } from "react";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import { CaseActionDisplay } from "./columns/CaseActionDisplay";
import { ShortageCaseFields, shortageCaseColMap } from "./case-types";

/**
 * MUI Grid `renderCell` function cannot directly call any hooks. This function wraps a `renderCell` function
 * to treat it as a normal component such that it can use React hooks as normal.
 */
const wrapRenderCell =
  (
    Component: FC<GridRenderCellParams>,
    componentProps?: ComponentProps<typeof Component>,
  ): GridBaseColDef["renderCell"] =>
  props =>
    <Component {...props} {...componentProps} />;

/**
 * MUI Grid `renderEditCell` function cannot directly call any hooks. This function wraps a `renderEditCell` function
 * to treat it as a normal component such that it can use React hooks as normal.
 */
const wrapRenderEditCell =
  (
    Component: FC<GridRenderEditCellParams>,
    componentProps?: ComponentProps<typeof Component>,
  ): GridBaseColDef["renderEditCell"] =>
  props =>
    <Component {...props} {...componentProps} />;

type GridColOptions = Omit<GridBaseColDef, "field" | "headerName">;

/**
 * Creates a column definition with preset defaults, verifying that `field` is a field of the expected work packet type,
 * and wrapping `renderCell` and `renderEditCell` into normal components so that they can use React hooks.
 */
function column(shortageCaseField: ShortageCaseFields, options: GridColOptions = EMPTY_OBJECT): GridBaseColDef {
  const { renderCell, renderEditCell, ...otherOptions } = options;
  return {
    field: shortageCaseField,
    headerName: shortageCaseColMap[shortageCaseField],
    width: 200,
    sortable: true,
    filterable: false,
    renderCell: renderCell && wrapRenderCell(renderCell),
    renderEditCell: renderEditCell && wrapRenderEditCell(renderEditCell),
    ...otherOptions,
  };
}

export const shortageCasesColumns = [
  column(ShortageCaseFields.ChargeGuardCaseId),
  column(ShortageCaseFields.AmazonCaseId),
  column(ShortageCaseFields.NumOfPackets),
  column(ShortageCaseFields.VendorName),
  column(ShortageCaseFields.StoreName),
  column(ShortageCaseFields.CurrentCaseOwner),
  column(ShortageCaseFields.CurrentAction, { renderCell: CaseActionDisplay }),
  column(ShortageCaseFields.CreatedAt),
  column(ShortageCaseFields.ManualFilingUser),
  column(ShortageCaseFields.RemainingOpenBalance),
  column(ShortageCaseFields.ApprovedAmount),
];
