import { TextField } from "@mui/material";
import { Field } from "formik";
import { type FC } from "react";
import { type ChargebackWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";

const getField = createFieldFn<ChargebackWorkPacket>();

export const DetailsWorkPacketInfo: FC = () => {
  return (
    <FormFieldsGroup
      title="Work Packet info"
      withDivider
      id="details-work-packet-info"
    >
      <Field
        name={getField("chargebackIssueId")}
        as={TextField}
        variant="outlined"
        placeholder="Chargeback Issue ID"
        size="small"
        label="Chargeback Issue ID"
        disabled
      />

      <Field
        name={getField("financialCharge")}
        as={TextField}
        variant="outlined"
        placeholder="Financial Charge"
        size="small"
        label="Financial Charge"
        disabled
      />
    </FormFieldsGroup>
  );
};
