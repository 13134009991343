import { TextField } from "@mui/material";
import { Field } from "formik";
import { type FC } from "react";
import { type AccrualWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";

const getField = createFieldFn<AccrualWorkPacket>();

export const ObarInfo: FC = () => {
  return (
    <FormFieldsGroup title="OBAR Info" withDivider id="obar-info">
      <Field name={getField("subType")} as={TextField} variant="outlined" size="small" label="Sub-Type" disabled />
      <Field name={getField("agreementId")} as={TextField} variant="outlined" size="small" label="Agreement" disabled />
      <Field
        name={getField("invoiceId")}
        as={TextField}
        variant="outlined"
        size="small"
        label="Invoice Number"
        disabled
      />
      <Field name={getField("invoiceDate")} as={TextField} variant="outlined" size="small" label="Invoice Date" disabled />
      <Field
        name={getField("currency")}
        as={TextField}
        variant="outlined"
        size="small"
        label="Currency"
        disabled
      />
      <Field
        name={getField("invoiceAmount")}
        as={TextField}
        variant="outlined"
        size="small"
        label="Invoice Amount"
        disabled
      />
    </FormFieldsGroup>
  );
};
