import { http } from "src/axios";
import type { CommandCenterGridRow, ServerVendor } from "src/types/work-packets";
import { parseDateRange } from "src/utils/date-range";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import type { SortOption } from "../api/workPacketsAPI";
import type { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType";
import apiClient from "src/pages/UserDashboard/WorkPackets/api/apiClient";
import { mapCommandCenterData } from "src/pages/UserDashboard/WorkPackets/mappers/mapServerCommandCenterData";
import { ShortageCase, ShortageCaseFields } from "./case-types";

// TODO: make this a subset of ShortageCaseFields
export const ServerCaseFilters = {
  AmazonCaseId: "amazonCaseId",
  CurrentCaseOwner: "caseOwnerId",
  CurrentAction: "currentAction",
  CreatedAt: "createdAt",
  VendorName: "vendorName",
  StoreName: "storeName",
} as const;

export type ServerCaseFilters = {
  [ServerCaseFilters.AmazonCaseId]: string;
  [ServerCaseFilters.CurrentAction]: string;
  [ServerCaseFilters.CurrentCaseOwner]: string;
  [ServerCaseFilters.CreatedAt]: string;
  [ServerCaseFilters.VendorName]: string;
  [ServerCaseFilters.StoreName]: string;
};

export type ServerFilterKeys = keyof typeof ServerCaseFilters;
export type ServerFilterValues = (typeof ServerCaseFilters)[keyof typeof ServerCaseFilters];

type ServerAccount = {
  ACTIVATION_DATE: string; // Date;
  ID: number;
  SCOPES: unknown[];
  SHORTAGE_LAG: string;
  STORE_NAME: string;
  TECH_USER: string | null;
  VC_FILING_USER: string | null;
  VENDOR: ServerVendor;
  VENDOR_ID: number;
};

type ServerCaseOwner = {
  EMAIL: string;
  FIRST_SEEN_AT: string; // Date,
  ID: string; // auth0Id,
  LAST_SEEN_AT: string; // Date,
  NAME: string;
  NICKNAME: string;
  PERMISSIONS: unknown[];
  PROFILE_PIC: string;
};

export type ServerShortageEscalation = {
  ACCOUNT: ServerAccount | null;
  ACCOUNT_ID: unknown;
  AMAZON_CASE_ID: string;
  CASE_OWNER: ServerCaseOwner | null;
  CASE_OWNER_ID: string | null; // auth0Id
  CREATED_AT: string; // Date;
  ID: string;
  NOTES: unknown | null;
  NO_OF_ITEMS: number;
  PAID_AMOUNT: number;
  REMAINING_OPEN_BALANCE: number;
  STATUS: string; // TODO: update with enum value
  TYPE: string; // TODO: update with enum value
};

/**
 * Maps the response from the server to the data grid type
 */
export const mapServerResponseToShortageCase = (serverCase: ServerShortageEscalation): ShortageCase => {
  return {
    chargeGuardCaseId: serverCase.ID,
    amazonCaseId: serverCase.AMAZON_CASE_ID,
    numOfPackets: serverCase.NO_OF_ITEMS,
    vendorName: serverCase.ACCOUNT?.VENDOR?.VENDOR_NAME || "",
    storeName: serverCase.ACCOUNT?.STORE_NAME || "",
    currentCaseOwner: serverCase.CASE_OWNER?.NAME || "",
    currentAction: serverCase.STATUS,
    createdAt: serverCase.CREATED_AT,
    manualFilingUser: serverCase.ACCOUNT?.VC_FILING_USER || "",
    remainingOpenBalance: serverCase.REMAINING_OPEN_BALANCE,
    approvedAmount: serverCase.PAID_AMOUNT, // unsure
  };
};

const computeFilters = (filters: ServerCaseFilters) => {
  const serverCaseFilters = Object.values(ServerCaseFilters);
  const computedFilters: Record<string, any> = Object.fromEntries(
    Object.entries(filters).filter(([key, val]) => {
      if (Array.isArray(val) && !val.length) return false;
      if (val === "") return false;
      return serverCaseFilters.includes(key as ServerFilterValues);
    }),
  );

  for (const dateRangeFilterName of [ServerCaseFilters.CreatedAt]) {
    if (dateRangeFilterName in computedFilters) {
      const dateRange = parseDateRange(computedFilters[dateRangeFilterName]);
      computedFilters[dateRangeFilterName] = dateRange?.map(date => date.format("YYYY-MM-DD")) ?? "";
    }
  }

  return computedFilters;
};

export const ServerCaseSorting = {
  [ShortageCaseFields.AmazonCaseId]: "amazonCaseId",
  [ShortageCaseFields.CurrentCaseOwner]: "caseOwnerId",
  [ShortageCaseFields.CurrentAction]: "currentAction",
  [ShortageCaseFields.CreatedAt]: "createdAt",
  [ShortageCaseFields.VendorName]: "vendorName",
  [ShortageCaseFields.StoreName]: "storeName",
  [ShortageCaseFields.ManualFilingUser]: "manualFilingUser",
  [ShortageCaseFields.NumOfPackets]: "numberOfPackets",
  [ShortageCaseFields.RemainingOpenBalance]: "remainingOpenBalance",
} as const;
export type ServerCaseSortingKey = keyof typeof ServerCaseSorting;

function computeSortOption(sorting?: SortOption): null | SortOption {
  if (!sorting || !(sorting.key in ServerCaseSorting)) return null;
  return {
    key: ServerCaseSorting[sorting.key as ServerCaseSortingKey],
    direction: sorting.direction,
  };
}

export interface FetchCasesArgs {
  page: number;
  pageSize: number;
  filters: ServerCaseFilters;
  sorting?: SortOption;
}

export interface FetchCasesResponse {
  cases: ShortageCase[];
  totalRecords: number;
  totalPages: number;
}
export enum ViewTab {
  AllCases = "All Cases",
  NewCases = "New Cases",
  MyCases = "My Cases",
}

export async function fetchCases(
  { page, pageSize, filters, sorting }: FetchCasesArgs,
  signal: AbortSignal,
): Promise<FetchCasesResponse> {
  const computedSortOption = computeSortOption(sorting);
  const computedFilters = computeFilters(filters);

  const response = await http.get("/api/v2/cases", {
    signal,
    params: {
      page: page + 1,
      per_page: pageSize,
      filters: JSON.stringify(computedFilters),
      sorting: JSON.stringify(
        computedSortOption ? { [computedSortOption.key]: computedSortOption.direction } : EMPTY_OBJECT,
      ),
      case_type: "SHORTAGE_ESCALATION",
    },
    errorMessage: "Error while fetching Cases",
  });
  return {
    cases: response.data.data.cases.map(mapServerResponseToShortageCase),
    totalRecords: response.data.data.total_records,
    totalPages: response.data.data.pages,
  };
}

export async function fetchCommandCenterData(
  workPacketType: WorkPacketType,
  caseType: "escalations" | "settlements",
  signal?: AbortSignal,
): Promise<CommandCenterGridRow[]> {
  const response = await apiClient.get(`/api/v2/cases/${workPacketType}/${workPacketType}_${caseType}/command_centre`, {
    signal,
    errorMessage: "Error while fetching command centre data.",
  });

  return mapCommandCenterData(response.data);
}
