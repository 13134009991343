import { Field } from "formik";
import { type FC } from "react";
import { TextAreaField } from "src/components/UI/Form";
import { type AccrualWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";
import Box from "@mui/material/Box";

const getField = createFieldFn<AccrualWorkPacket>();

export const AccrualNotes: FC = () => {
  return (
    <FormFieldsGroup title="Notes" id="notes" withDivider>
      <Box sx={{ gridColumn: { xs: "span 1", md: "span 2" } }}>
        <Field name={getField("notes")} component={TextAreaField} label="Notes" />
      </Box>
    </FormFieldsGroup>
  );
};
