import { useQuery } from "@tanstack/react-query";
import { getChargebackSubtypes } from "../../../queries/work-packet-subtypes";

export const useChargebackSubtypes = () => {
  const query = useQuery({
    queryKey: ["chargeback-subtypes"],
    queryFn: getChargebackSubtypes,
    staleTime: 30000,
  });
  return query.data;
};
