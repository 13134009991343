import { FC } from "react";
import { FileUploadBox } from "../components/FileUploadBox";
import { FormFieldsGroup } from "../components";

export const EvidenceAttachments: FC = () => {
  return (
    <FormFieldsGroup withDivider title="Attachments" id="attachments">
      <FileUploadBox />
    </FormFieldsGroup>
  );
};
