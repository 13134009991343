import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { type FC } from "react";
import { type ChargebackWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";

export const DetailsFirstDispute: FC = () => {
  const { initialValues: packet } = useFormikContext<ChargebackWorkPacket>();
  const issueDate = packet.firstDisputeCreatedAt;
  if (!issueDate) return null;

  return (
    <FormFieldsGroup
      title="First Dispute"
      withDivider
      id="details-first-dispute"
    >
      <TextField
        value={issueDate}
        variant="outlined"
        placeholder="First Dispute - Chargeback Issue Date"
        size="small"
        label="First Dispute - Chargeback Issue Date"
        disabled
      />
    </FormFieldsGroup>
  );
};
