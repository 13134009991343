import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getUsersWithPermissions } from "src/queries/users";

export const useUserNickname = () => {
  const { user } = useAuth0();

  const { data: usersList } = useQuery({
    queryKey: ["users"],
    queryFn: getUsersWithPermissions,
    staleTime: Infinity, // user nickname likely won't change often
  });

  const userNickname = useMemo(
    () => (user && usersList?.find(x => x.ID === user.sub)?.NICKNAME) ?? "Unknown user",
    [user, usersList],
  );

  return userNickname;
};
