import { type AxiosProgressEvent } from "axios";
import { createContext } from "react";
import { type ServerModelAttachment } from "src/types/work-packets";

export type FileUploadStatus =
  | {
      status: "pending" | "uploaded";
    }
  | {
      status: "uploading";
      uploadProgress: AxiosProgressEvent;
    }
  | {
      status: "failed";
      error: string;
    };

export type FileUpload = {
  file: File;
} & FileUploadStatus;

export interface FileUploadStore {
  existingFiles: ServerModelAttachment[];
  isLoadingExistingFiles: boolean;
  fileUploads: FileUpload[];
  isUploading: boolean;
  hasPendingUploads: boolean;
  enqueueFileUpload(file: File[]): void;
  removeFileUpload(filename: string): void;
  setFileUploadStatus(filename: string, newStatus: FileUploadStatus): void;
}

export const FileUploadContext = createContext<FileUploadStore>(undefined!);
