import { CHINESE, STANDARD } from "../util/constants";

export const vmPermissionOptions = [
  { label: "Select Vendor Manager Permissions", value: "" },
  {
    label: "Permission to fully connect",
    value: "Permission to fully connect",
  },
  {
    label: "WL-Permission to fully connect",
    value: "WL-Permission to fully connect",
  },
  { label: "WL-Ask Client First", value: "WL-Ask Client First" },
  { label: "WL-Friction Point", value: "WL-Friction Point" },
  {
    label: "WL-Contact with Client in Copy",
    value: "WL-Contact with Client in Copy",
  },
  { label: "Ask Client for VM", value: "Ask Client for VM" },
  { label: "Ask Client First", value: "Ask Client First" },
  { label: "Friction Point", value: "Friction Point" },
  {
    label: "Contact with Client in Copy",
    value: "Contact with Client in Copy",
  },
];

export const shortageProcessingOptions = [
  { label: "Select Shortage Processing", value: "" },
  { label: "Historic PQV", value: "Historic PQV" },
  { label: "PQV MDT Processing", value: "PQV MDT Processing" },
  { label: "Not in Scope", value: "Not in Scope" },
];

export const priceClaimProcessingOptions = [
  { label: "Select Price Claim Processing", value: "" },
  { label: "Historic PPV", value: "Historic PPV" },
  { label: "PPV MDT Processing", value: "PPV MDT Processing" },
  { label: "Not in Scope", value: "Not in Scope" },
];

export const dataPullStatusOptions = [
  { label: "Select Data Pull Status", value: "" },
  { label: "N/A", value: 6 },
  { label: "Data Pull Requested", value: 5 },
  { label: "Data Pull Complete", value: 3 },
  { label: "Client Configuration", value: 4 },
  { label: "CGI Dashboard Ready", value: 7 },
  { label: "CGI Org Complete", value: 2 },
  { label: "(Historic)Client Configuration Complete", value: 1 },
  {label: "(WL) Client Configuration Complete", value: 8},
];

export const adminPermissionOptions = [
  { label: "False", value: "false" },
  { label: "True", value: "true" },
];

export const categoryOptions = [
  { label: "Select Category", value: "" },
  { label: "Automotive", value: "Automotive" },
  { label: "Baby", value: "Baby" },
  { label: "Beauty", value: "Beauty" },
  { label: "Books", value: "Books" },
  { label: "Cell Phones & Accessories", value: "Cell Phones & Accessories" },
  { label: "Clothing, Shoes & Jewelry", value: "Clothing, Shoes & Jewelry" },
  { label: "Electronics", value: "Electronics" },
  { label: "Grocery", value: "Grocery" },
  { label: "Health & Household", value: "Health & Household" },
  { label: "Home & Kitchen", value: "Home & Kitchen" },
  { label: "HPC", value: "HPC" },
  { label: "Industrial & Scientific", value: "Industrial & Scientific" },
  { label: "Kitchen & Dining", value: "Kitchen & Dining" },
  { label: "Musical Instruments", value: "Musical Instruments" },
  { label: "n/a", value: "n/a" },
  { label: "Office", value: "Office" },
  { label: "Office Products", value: "Office Products" },
  { label: "Patio, Lawn & Garden", value: "Patio, Lawn & Garden" },
  { label: "Pet", value: "Pet" },
  { label: "Pet Supplies", value: "Pet Supplies" },
  { label: "Sports & Outdoor", value: "Sports & Outdoor" },
  { label: "Tools & Home Improvement", value: "Tools & Home Improvement" },
  { label: "Toys & Games", value: "Toys & Games" },
];

export const shortageLagOptions = [
  { label: "Select Shortage Lag", value: "" },
  { label: "30", value: 30 },
  { label: "35", value: 35 },
  { label: "60", value: 60 },
  { label: "90", value: 90 },
  { label: "N/A", value: "N/A" },
  { label: "45", value: 45 },
  { label: "40", value: 40 },
  { label: "75", value: 75 },
  { label: "120", value: 120 },
];

export const cadenceOptions = [
  { label: "Select SH Trigger Dispute Cadence", value: "" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Daily", value: "Daily" },
];


export const OptionWhiteLabelled = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "Other", value: "Other" },
];


export const isHistoricOptions = [
  { label: "False", value: false },
  { label: "True", value: true },
];

export const accountStatuses = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Retired", value: "retired" },
];

export const partnerPaymentMethods = [
  { label: "Select Payment Method", value: "" },
  { label: "Partnerstack", value: "Partnerstack" },
  { label: "Deel", value: "Deel" },
  { label: "Direct Deposit/Wire", value: "Direct Deposit/Wire" },
  { label: "Other", value: "Other" },
];

export const partnerTypes = [
  { label: "Select Partner Type", value: "" },
  { label: "Chinese Affiliate", value: "Chinese Affiliate" },
  { label: "Standard Affiliate", value: "Standard Affiliate" },
  { label: "Strategic Partnership", value: "Strategic Partnership" },
  { label: "Other", value: "Other" },
];

export const vendorTypeOptions = [
  { label: "Select an option", value: "" },
  { label: CHINESE, value: CHINESE },
  { label: STANDARD, value: STANDARD },
];

export const billingEntityOptions = [
  { label: "Select an option", value: "" },
  { label: "Carbon6", value: "Carbon6" },
  { label: "China", value: "China" },
];
