import { type MutationFunction, type QueryFunction } from "@tanstack/react-query";
import { type ServerModelAttachment, type WorkPacket } from "src/types/work-packets";
import { getWorkPacketAttachments, uploadWorkPacketAttachment } from "../../api/workPacketsAPI";
import { type FileUploadStore, type FileUpload } from "./FileUploadContext";
import { type WorkPacketType } from "../../WorkPacketType";

export const getWorkPacketAttachmentList: QueryFunction<
  ServerModelAttachment[],
  ["work-packets", type: WorkPacketType, "details", packetId: string, "attachments"]
> = async ({ queryKey, signal }) => getWorkPacketAttachments(queryKey[1], signal);

export interface AttachmentUploadVariables {
  workPacket: WorkPacket;
  fileUploads: FileUpload[];
  setFileUploadStatus: FileUploadStore["setFileUploadStatus"];
}

export const uploadWorkPacketAttachments: MutationFunction<void, AttachmentUploadVariables> = async ({
  workPacket,
  fileUploads,
  setFileUploadStatus,
}) => {
  let failureCount = 0;
  for (const fileUpload of fileUploads) {
    if (fileUpload.status !== "pending") continue;
    await uploadWorkPacketAttachment(workPacket.packetId, fileUpload.file, uploadProgress => {
      setFileUploadStatus(fileUpload.file.name, { status: "uploading", uploadProgress });
    })
      .then(() => {
        setFileUploadStatus(fileUpload.file.name, { status: "uploaded" });
      })
      .catch(err => {
        failureCount += 1;
        setFileUploadStatus(fileUpload.file.name, { status: "failed", error: err.message });
        console.error("upload failure", err);
      });
  }
  if (failureCount > 0) throw new Error("Attachment upload failed");
};
