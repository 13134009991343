type Tier = "Enterprise" | "Mid Market" | "SMB";

export const getColorByCustomerTier = (tier: Tier): string => {
  const colorMap = {
    "Enterprise": "gold",
    "Mid Market": "silver",
    "SMB": "#A5552B",
  };

  return colorMap[tier];
};
