import { http } from "src/axios.ts";
import { WorkPacketDisputesId, WorkPacketFilters } from "src/types/work-packets";
import { computeFilters } from "src/pages/UserDashboard/WorkPackets/api/workPacketsAPI";
import { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType.ts";
import { encodeUrlParams } from "./encodeUrlParams";

export async function createCase({
  caseId,
  caseType,
  caseCreatedAt,
  workPacketIds,
  excludedWorkPacketIds,
  filters,
}: {
  caseId: string;
  workPacketIds: string[];
  excludedWorkPacketIds: string[];
  filters: WorkPacketFilters;
  caseType: string;
  caseCreatedAt: string;
}) {
  const payload = {
    case_id: caseId,
    work_packet_ids: workPacketIds,
    case_type: caseType,
    case_created_at: caseCreatedAt,
    excluded_work_packets: excludedWorkPacketIds,
    filters: JSON.stringify(computeFilters(filters, WorkPacketType.SHORTAGES)),
  };
  const response = await http.post(`/api/v2/cases`, payload, {
    errorMessage: "Error while creating case",
  });
  return response.data;
}

export async function getDisputesIdsAndVendorInfo({
  workPacketIds,
  excludedWorkPackets,
  filters,
}: {
  workPacketIds: string[];
  excludedWorkPackets: string[];
  filters: WorkPacketFilters;
}): Promise<WorkPacketDisputesId> {
  const params = encodeUrlParams({
    work_packet_ids: workPacketIds,
    excluded_work_packets: excludedWorkPackets,
    filters: JSON.stringify(computeFilters(filters, WorkPacketType.SHORTAGES)),
  });
  const response = await http.get(`api/v2/work_packets/shortages/pre_create_case`, { params });
  return response.data.data as WorkPacketDisputesId;
}
