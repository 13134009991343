import { Box, Tooltip, Typography } from "@mui/material";
import { Icon } from "components/UI/Icon";
import { NavLink } from "react-router-dom";

interface SidebarLinkProps {
  title: string;
  onClick?: () => void;
  to: string;
  iconName: string;
  minimized?: boolean;
  isSubItem?: boolean;
  matchExactParams?: string[];
}

const SidebarLink = ({
  to,
  iconName,
  title,
  onClick,
  minimized,
  isSubItem,
  matchExactParams = [],

}: SidebarLinkProps) => {
  const linkUrl = new URL(to, window.location.href);
  const windowUrl = new URL(window.location.href);
  return (
    <NavLink
      end
      style={({ isActive: _isActive }) => {
        const isActive =
          _isActive &&
          matchExactParams.every(
            paramName => linkUrl.searchParams.has(paramName) === windowUrl.searchParams.has(paramName),
          );

        return ({
          display: "block",
          color: isActive
            ? isSubItem && minimized
              ? "#0A0F27"
              : "white"
            : isSubItem
              ? "#667085"
              : "#0A0F27",
          backgroundColor: isActive
            ? isSubItem && minimized
              ? "transparent"
              : "#F7104D"
            : "",
          borderRadius: "8px",
          textDecoration: "none",
          hover: {
            backgroundColor: isActive ? "#F7104D" : "#FFE4E8",
            svg: {
              color: isActive ? "white" : "#F7104D",
            },
          },
          transition: "all 0.1s",
        });
      }}
      className={({ isActive: _isActive }) => {
        const isActive = _isActive && linkUrl.searchParams.has("preCase") === windowUrl.searchParams.has("preCase");
        return isActive ? "btn-hover active" : "btn-hover";
      }}
      onClick={onClick}
      to={to}
    >
      <Tooltip
        title={title}
        placement="right-end"
        disableHoverListener={!minimized}
      >
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          paddingX="12px"
          paddingY="10px"
        >
          <Box>
            <Icon
              // @ts-ignore
              name={iconName}
              color="inherit"
              width={isSubItem ? "20px" : "24px"}
              height={isSubItem ? "20px" : "24px"}
            />
          </Box>
          <Box display="flex" width="100%">
            <Typography
              variant="body2"
              noWrap
              sx={{
                opacity: minimized ? 0 : 1,
                visibility: minimized ? "hidden" : "visible",
                transition: "opacity 0.3s",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    </NavLink>
  );
};

export default SidebarLink;
