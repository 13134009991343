import { Box, Button, CircularProgress, Collapse, IconButton } from "@mui/material";
import { DataGridPro, type GridPinnedColumnFields, useGridApiRef } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from "react";
import { WorkPacketsGridFilters } from "./filters/WorkPacketsGridFilters";
import { getWorkPacketGridColumnGrouping, getWorkPacketsGridColumns } from "./helpers/getWorkPacketsGridColumns";
import { CloudDownloadOutlined, FilterList } from "@mui/icons-material";
import { WorkPacketDetailsPopup } from "../WorkPacketDetailsPopup";
import { useWorkPacketsGrid } from "../useWorkPacketsGrid";
import useColumnVisibility from "src/utils/grid/useColumnVisibility";
import { WorkPacketView } from "../WorkPacketView";
import { BulkUpdatePopup } from "./BulkUpdatePopup";
import usePermissions from "components/CustomHooks/usePermissions";
import { useWorkPacketsContext } from "../WorkPacketsContext";
import { WorkPacketType } from "../WorkPacketType";
import { WorkPacketPopupContext } from "./WorkPacketPopupContext";
import { CreateCasePopup } from "src/pages/UserDashboard/Cases/CreateCasePopup";
import { WorkPacketFilter } from "src/types/work-packets.ts";

const pinnedColumns: GridPinnedColumnFields = {
  right: ["_generateObar", "_details"],
};

interface WorkPacketsGridProps<T extends WorkPacketType> {
  workPacketType: T;
  onDataUpdate?: () => void;
}

const WorkPacketsGrid = function WorkPacketsGrid<T extends WorkPacketType>({
  workPacketType,
  onDataUpdate,
}: WorkPacketsGridProps<T>) {
  const apiRef = useGridApiRef();
  const { isAdmin } = usePermissions();
  const [showFilters, setShowFilters] = useState(true);
  const [currentPacketId, setCurrentPacketId] = useState<string | null>(null);
  const [showBulkUpdatePopup, setShowBulkUpdatePopup] = useState(false);
  const [showCreateCasePopup, setShowCreateCasePopup] = useState(false);

  const { currentFilters, currentView: view } = useWorkPacketsContext();
  const isPreCaseView = !!currentFilters[WorkPacketFilter.PreCase];

  const {
    data,
    paginationModel,
    total,
    loading,
    filters,
    rowSelection,
    setRowSelection,
    callbacks,
    selectAllEnabled,
    excludeWorkPacketIds,
    triggerFetchWorkPackets,
    rowUpdateSignal,
    handleExport,
    isExporting,
  } = useWorkPacketsGrid(workPacketType);

  useEffect(() => {
    onDataUpdate && onDataUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowUpdateSignal]);

  const allowEditPacketActionAndNotes = isAdmin || view === WorkPacketView.MyPackets;

  const columns = getWorkPacketsGridColumns(workPacketType);
  const columnGroupingModel = getWorkPacketGridColumnGrouping(workPacketType);

  const { columnVisibilityModel, handleColumnVisibilityChange } = useColumnVisibility("work-packets-grid");

  const toggleFilters = () => {
    setShowFilters(prev => !prev);
  };

  const isCellEditable = useCallback(() => allowEditPacketActionAndNotes, [allowEditPacketActionAndNotes]);

  return (
    <>
      <Box bgcolor="#fff" p={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" gap="12px">
            <Button
              startIcon={<FilterList />}
              variant="outlined"
              onClick={toggleFilters}
              className={showFilters ? "" : "active"}
            >
              Filters
            </Button>
          </Box>

          <Box display="flex" gap="12px">
            {workPacketType === WorkPacketType.SHORTAGES && isPreCaseView && (
              <Button
                variant="outlined"
                disabled={rowSelection.length === 0}
                onClick={() => {
                  console.log("Current selection", { rowSelection });
                  setShowCreateCasePopup(true);
                }}
              >
                Attach a Case ID
              </Button>
            )}
            <Button
              variant="outlined"
              disabled={rowSelection.length === 0}
              onClick={() => {
                console.log("Current selection", { rowSelection });
                setShowBulkUpdatePopup(true);
              }}
            >
              Bulk Actions
            </Button>
            {isAdmin && (
              <IconButton className="outlined" onClick={handleExport} disabled={loading || isExporting}>
                <CloudDownloadOutlined />

                {isExporting && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
              </IconButton>
            )}
          </Box>
        </Box>

        <Collapse in={showFilters}>
          <WorkPacketsGridFilters loading={loading} />
        </Collapse>

        <Box>
          <WorkPacketPopupContext.Provider value={setCurrentPacketId}>
            <DataGridPro
              {...callbacks}
              apiRef={apiRef}
              rows={data}
              isCellEditable={isCellEditable}
              columns={columns}
              pinnedColumns={pinnedColumns}
              columnGroupingModel={columnGroupingModel}
              getRowId={row => row.packetId}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              rowCount={total}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              filterMode="server"
              sortingMode="server"
              rowSelectionModel={rowSelection}
              keepNonExistentRowsSelected
              checkboxSelection
              disableRowSelectionOnClick
              loading={loading}
              rowHeight={70}
              autoHeight
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
            />
          </WorkPacketPopupContext.Provider>
        </Box>
      </Box>

      {currentPacketId != null && (
        <WorkPacketDetailsPopup
          view={view}
          workPacketId={currentPacketId}
          open
          onClose={() => setCurrentPacketId(null)}
          onSuccessfulUpdate={() => {
            triggerFetchWorkPackets();

            // Trigger summary data re-fetch
            onDataUpdate && onDataUpdate();
          }}
        />
      )}

      {showBulkUpdatePopup && (
        <BulkUpdatePopup
          open={showBulkUpdatePopup}
          onClose={(success?: boolean) => {
            setShowBulkUpdatePopup(false);

            // Refetch data if the bulk update was successful
            if (success) {
              triggerFetchWorkPackets();

              // Reset selection
              setRowSelection([]);

              // Trigger summary data re-fetch
              onDataUpdate && onDataUpdate();
            }
          }}
          workPacketIds={rowSelection as string[]}
          filters={filters}
          selectedAll={selectAllEnabled}
          excludeIds={excludeWorkPacketIds}
          allowEditPacketActionAndNotes={allowEditPacketActionAndNotes}
        />
      )}
      {showCreateCasePopup && (
        <CreateCasePopup
          open={showCreateCasePopup}
          excludedWorkPacketsIds={excludeWorkPacketIds}
          filters={filters}
          onClose={() => setShowCreateCasePopup(false)}
          selectedWorkPacketsIds={selectAllEnabled ? [] : (rowSelection as string[])}
        />
      )}
    </>
  );
};

export default WorkPacketsGrid;
