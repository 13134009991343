import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { type AccrualWorkPacket, type WorkPacketTypeMap, StageName } from "src/types/work-packets";
import { capitalize } from "src/utils/capitalize";
import { WorkPacketType } from "../../../WorkPacketType";

interface NavigationEntry {
  title: string;
  id: string;
  subItems: {
    title: string;
    id: string;
  }[];
}

const chargebackSummaryNavigation: NavigationEntry[] = [
  {
    title: "General Packet Info",
    id: "general-packet-info",
    subItems: [
      {
        title: "Customer Reference",
        id: "customer-reference",
      },
      {
        title: "Work Packet info",
        id: "work-packet-info",
      },
    ],
  },
  {
    title: "Packet Details",
    id: "packet-details",
    subItems: [
      {
        title: "Work Packet info",
        id: "details-work-packet-info",
      },
      {
        title: "First Dispute",
        id: "details-first-dispute",
      },
      {
        title: "Second Dispute",
        id: "details-second-dispute",
      },
    ],
  },
];

const shortageSummaryNavigation: NavigationEntry[] = [
  {
    title: "General Packet Info",
    id: "general-packet-info",
    subItems: [
      {
        title: "Customer Reference",
        id: "customer-reference",
      },
      {
        title: "Work Packet info",
        id: "work-packet-info",
      },
    ],
  },
  {
    title: "Packet Details",
    id: "packet-details",
    subItems: [
      {
        title: "Dispute Information",
        id: "dispute-information",
      },
      {
        title: "Invoice Information",
        id: "invoice-information",
      },
    ],
  },
];

const stageNumberMap = [StageName.First, StageName.Second, StageName.Third];

const getAccrualSummaryNavigation = (packet: AccrualWorkPacket) => [
  {
    title: "General Packet Info",
    id: "general-packet-info",
    subItems: [
      {
        title: "Customer Reference",
        id: "customer-reference",
      },
    ],
  },
  {
    title: "Packet Details",
    id: "packet-details",
    subItems: [
      {
        title: "OBAR Info",
        id: "obar-info",
      },
      ...packet.disputes.map((_dispute, index) => {
        const stageNumber = stageNumberMap[index] ?? `${index + 1}th`;
        const capitalizedStageNumber = capitalize(stageNumber);
        return {
          title: `${capitalizedStageNumber} Trigger Dispute`,
          id: `trigger-dispute-${index}`,
        };
      }),
      {
        title: "Notes",
        id: "notes",
      },
      {
        title: "Attachments",
        id: "attachments",
      },
    ],
  },
];

type WorkPacketTypeToNavigationMap = {
  [T in WorkPacketType]: (packet: WorkPacketTypeMap[T]) => NavigationEntry[];
};

const workPacketTypeToNavigationMap: WorkPacketTypeToNavigationMap = {
  [WorkPacketType.CHARGEBACKS]: () => chargebackSummaryNavigation,
  [WorkPacketType.SHORTAGES]: () => shortageSummaryNavigation,
  [WorkPacketType.ACCRUALS]: getAccrualSummaryNavigation,
};

export interface SummaryNavigationProps<T extends WorkPacketType> {
  workPacket: WorkPacketTypeMap[T];
  workPacketType: T;
}

export function SummaryNavigation<T extends WorkPacketType>({ workPacket, workPacketType }: SummaryNavigationProps<T>) {
  const handleScrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigationEntries = useMemo(
    () => workPacketTypeToNavigationMap[workPacketType](workPacket),
    [workPacketType, workPacket],
  );

  return (
    <Box position="sticky" top={0} height="100%" padding={2} sx={{ display: { xs: "none", md: "flex" } }}>
      <Stack spacing={1.5}>
        {navigationEntries.map(item => (
          <Box key={item.id}>
            <Typography onClick={() => handleScrollTo(item.id)} fontWeight="500" sx={navItemStyles}>
              {item.title}
            </Typography>
            <Stack spacing={1.5} paddingLeft={2} marginTop={1.5}>
              {item.subItems.map(subItem => (
                <Typography key={subItem.id} onClick={() => handleScrollTo(subItem.id)} sx={navItemStyles}>
                  {subItem.title}
                </Typography>
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

const navItemStyles = {
  color: "#667085",
  fontSize: "14px",
  cursor: "pointer",
  "&:hover": {
    color: "#F7104D",
  },
};
