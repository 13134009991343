import { Box } from "@mui/material";
import { type GridRenderCellParams } from "@mui/x-data-grid";
import { type WorkPacket, type WorkPacketActionName } from "src/types/work-packets";
import { getActionObject } from "../../mappers/mapActionNameToPacketActionObject";

export const CurrentActionDisplay = (params: GridRenderCellParams<WorkPacket, WorkPacketActionName>) => {
  if (!params.value) return "";
  const { color, title } = getActionObject(params.value, params.row.workPacketType);

  return (
    <Box display="flex" alignItems="center">
      {!!color && <Box width="12px" minWidth="12px" height="12px" bgcolor={color} borderRadius="2px" />}
      <Box ml={1}>{title}</Box>
    </Box>
  );
};
