import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { useCallback, type FC } from "react";

import { WorkPacketFilters } from "src/types/work-packets";
import { initialFilters, useWorkPacketsContext } from "../../WorkPacketsContext";
import { WorkPacketType } from "../../WorkPacketType";

import { AccrualFiltersForm } from "./AccrualFiltersForm";
import { ChargebackFiltersForm } from "./ChargebackFiltersForm";
import { ShortageFiltersForm } from "./ShortageFiltersForm";

const areFiltersEqual = (
  currentFilters: Partial<WorkPacketFilters>,
  nextFilters: Partial<WorkPacketFilters>,
): boolean =>
  Object.entries(nextFilters).every(([filterKey, newValue]) => {
    const previousValue = currentFilters[filterKey as keyof WorkPacketFilters];
    if (Array.isArray(previousValue) && Array.isArray(newValue)) {
      if (previousValue.length !== newValue.length) return false;
      return previousValue.every((previousArrayEntry, index) => previousArrayEntry === newValue[index]);
    }
    return previousValue === newValue;
  });

const workPacketTypeToFiltersFormMap: Record<WorkPacketType, FC> = {
  [WorkPacketType.CHARGEBACKS]: ChargebackFiltersForm,
  [WorkPacketType.SHORTAGES]: ShortageFiltersForm,
  [WorkPacketType.ACCRUALS]: AccrualFiltersForm,
};

const FormikForm: FC<{ loading: boolean }> = ({ loading }) => {
  const { currentFilters, clearFilters, currentWorkPacketType } = useWorkPacketsContext();
  const { resetForm, dirty, values: nextFilters } = useFormikContext<WorkPacketFilters>();

  const hasNonEmptyValues = !areFiltersEqual(currentFilters, initialFilters);
  const FormComponent = workPacketTypeToFiltersFormMap[currentWorkPacketType];

  return (
    <Form>
      <Box display="flex" flexDirection="column" mb={4}>
        <FormComponent />

        <Box mt={2} display="flex" justifyContent="flex-end" gap="12px">
          {dirty && (
            <Button style={{ paddingLeft: "12px", paddingRight: "12px" }} onClick={() => resetForm()}>
              Cancel
            </Button>
          )}
          {hasNonEmptyValues && (
            <Button
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
              onClick={() => {
                clearFilters();
                resetForm({ values: initialFilters });
              }}
            >
              Clear
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            disabled={areFiltersEqual(currentFilters, nextFilters) || loading}
            sx={{ paddingX: "24px" }}
          >
            <span>Apply</span>
            {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export interface FiltersGridProps {
  loading: boolean;
}

export const WorkPacketsGridFilters: FC<FiltersGridProps> = ({ loading }) => {
  const { currentFilters, setFilters, currentWorkPacketType } = useWorkPacketsContext();
  const onSubmit = useCallback((values: WorkPacketFilters) => setFilters(values), [setFilters]);

  return (
    <Formik
      /* A key to force reinitialization when view changes */
      key={currentWorkPacketType}
      initialValues={currentFilters}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <FormikForm loading={loading} />
    </Formik>
  );
};
