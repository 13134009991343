import React, {useMemo, useState} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {Routes_Main} from "./MainRoutes";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";
import ForgotPassword from "./components/main-components/Forgot-Password/ForgetPassword";
import EmailVerification from "./components/main-components/Email-Verification/EmailVerification";
import Error404 from "./ErrorPages/404/404";
import ShortageDispute from "./pages/UserDashboard/ShortageDispute/ShortageDispute";
import { OssrPage } from "./pages/UserDashboard/Ossr/OssrPage";
import UsageHistory from "./pages/UserDashboard/UsageHistory/UsageHistory";
import DisputeTool from "./pages/UserDashboard/CBDispute/DisputeTool/DisputeTool";
import InvoicingAutomation from "./pages/UserDashboard/Invoicing Automation/InvoicingAutomation";
import ManagePhrase from "./pages/UserDashboard/CBDispute/ManagePhrase/ManagePhrase";
import Audit from "./pages/UserDashboard/Audits/Audit";
import Configuration from "./pages/UserDashboard/CBDispute/Configuration/Configuration";
import OssrNew from "./pages/UserDashboard/OssrNew/OssrNew";
import { Auth0 } from "./components/Auth0/Auth0";
import { AuthenticationGuard } from "./Routes/AuthenticationGuard";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import ViewOneVendor from "./pages/UserDashboard/Customers/ViewOneVendor.jsx";
import OBAR from "./pages/UserDashboard/OBAR/OBAR";
import Customers from "./pages/UserDashboard/Customers/Customers.tsx";
import { PermissionGuard } from "./Routes/PermissionGuard";
import { WorkPackets } from "./pages/UserDashboard/WorkPackets/WorkPackets.tsx";
import { UsersPage } from "./pages/UserDashboard/Users/UsersPage";
import { Cases } from "./pages/UserDashboard/WorkPackets/Cases/Cases.tsx";
import { LicenseInfo } from "@mui/x-license";

import { queryClient } from "./query-client";
import { QueryClientProvider } from "@tanstack/react-query";
import { AppContext } from "./AppContext";
import { SingleCasePage } from "./pages/UserDashboard/WorkPackets/Cases/SingleCasePage.tsx";

/**
 * MUI License Key for Grid Pro Component
 */
const MUI_LICENCE_KEY = import.meta.env.VITE_REACT_APP_MUI_LICENCE_KEY || "";
LicenseInfo.setLicenseKey(MUI_LICENCE_KEY);

const App = () => {
  const [menuCollapse, setMenuCollapse] = useState(false);

  const [accountID, setAccountID] = useState("");
  const [vendorID, setVendorID] = useState("");

  const [permissions, setPermissions] = useState([]);

  const appContext = useMemo(() => ({
    menuCollapse,
    setMenuCollapse,
    setPermissions,
    permissions,
    //context for client configs
    accountID,
    setAccountID,
    setVendorID,
    vendorID,
  }), [
    menuCollapse,
    setMenuCollapse,
    setPermissions,
    permissions,
    accountID,
    setAccountID,
    setVendorID,
    vendorID,
  ]);

  return (
      <div className="App">
        <Toaster/>
        <QueryClientProvider client={queryClient}>
          <AppContext.Provider value={appContext}>
            <Router>
              <Auth0>
                <Routes>
                  {/* <Route path={Routes_Main.userLogin} element={<UProtectedRoute />} /> */}
                  <Route
                    path="ossr"
                    element={
                      <PermissionGuard
                        permissions={["admin:*", "user:shortages:*", "user:ossr:*"]}
                        element={OssrPage}
                      />
                    }
                  />
                  <Route path="ossr/legacy" element={<OssrNew />} />
                  <Route
                    path="massdispute"
                    element={<PermissionGuard permissions={["admin:*", "user:chargebacks:*"]} element={DisputeTool} />}
                  />
                  <Route
                      path="user-dashboard"
                      element={<AuthenticationGuard component={UserDashboard}/>}
                  >
                    <Route
                        path="ossr"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:shortages:*', 'user:ossr:*']} element={OssrPage}/>}
                    />
                    <Route path="ossr/legacy" element={<OssrNew/>}/>
                    <Route
                        path="massdispute"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:chargebacks:*']}
                            element={DisputeTool}/>}
                    />
                    <Route
                        path="configuration"
                        element={<PermissionGuard
                            permissions={['admin:*']}
                            element={Configuration}/>}
                    />
                    <Route
                        path="disputephrases"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:chargebacks:write:*']}
                            element={ManagePhrase}/>}
                    />
                    <Route
                        path="usageHistory"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:chargebacks:*']}
                            element={UsageHistory}/>}
                    />
                    <Route
                        path="shortage-dispute"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:shortages:*']}
                            element={ShortageDispute}/>}
                    />
                    <Route
                        path="invoicing-automation"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:invoicing:*']}
                            element={InvoicingAutomation}/>}
                    />
                    <Route
                        path="audits"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:audit:*']}
                            element={Audit}/>}
                    />
                    <Route
                        path="customers"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:client_config:*']}
                            element={Customers}/>}
                    />
                    <Route
                        path="vendors"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:client_config:*']}
                            element={ViewOneVendor}/>}
                    />
                    <Route
                        path="obar/view"
                        element={<PermissionGuard
                            permissions={['admin:*', 'user:accruals:*']}
                            element={OBAR}/>}
                    />
                    <Route path="work-packets" element={<Navigate to="/user-dashboard/work-packets/chargebacks" replace  />} />
                    <Route
                      path="work-packets/chargebacks"
                      element={
                        <PermissionGuard
                          permissions={[
                            'admin:*',
                            'user:chargebacks_work_packet:*',
                          ]}
                          element={WorkPackets}
                          key="work-packets-chargebacks"
                        />
                      }
                    />
                    <Route
                      path="work-packets/shortages"
                      element={
                        <PermissionGuard
                          permissions={[
                            'admin:*',
                            'user:shortages_work_packet:*',
                          ]}
                          element={WorkPackets}
                          key="work-packets-shortages"
                        />
                      }
                    />
                    <Route path="work-packets/shortages/:caseType" element={<Cases />} />
                    <Route
                      path="work-packets/shortages/:caseType/:caseId"
                      element={
                        <PermissionGuard
                          permissions={[
                            'admin:*',
                            'user:shortages_work_packet:*',
                          ]}
                          element={SingleCasePage}
                          key="work-packets-shortages"
                        />
                      }
                    />
                    <Route
                      path="work-packets/shortages/:caseType/:caseId/:tab"
                      element={
                        <PermissionGuard
                          permissions={[
                            'admin:*',
                            'user:shortages_work_packet:*',
                          ]}
                          element={SingleCasePage}
                          key="work-packets-shortages"
                        />
                      }
                    />
                    <Route
                      path="work-packets/accruals"
                      element={
                        <PermissionGuard
                          permissions={[
                            'admin:*',
                            'user:accruals_work_packet:*',
                          ]}
                          element={WorkPackets}
                          key="work-packets-accruals"
                        />
                      }
                    />
                    <Route
                      path="users"
                      element={
                        <PermissionGuard
                          permissions={["admin:*"]}
                          element={UsersPage} />}
                    />
                  </Route>
                  <Route path="*" element={<Error404/>}/>
                </Routes>
              </Auth0>
            </Router>
          </AppContext.Provider>
        </QueryClientProvider>
      </div>
  );
};

export default App;
