import type { Paths } from "type-fest";

/**
 * Creates a function that returns the string passed into it.
 * The function type signature will only allow the string to be a property name of `T`,
 * or a path to a property of `T` using dot notation.
 */
export const createFieldFn =
  <T>() =>
  <K extends Paths<T, { bracketNotation: true }>>(fieldName: K): K =>
    fieldName;
