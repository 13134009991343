import { type WorkPacket } from "src/types/work-packets";
import { isDefined } from "src/utils/isDefined";
import type { UpdatablePacketKey, UpdatableValue, UpdatableValues } from "../api/workPacketsAPI";

const updateValueGetters: { [K in UpdatablePacketKey]: (packet: WorkPacket) => UpdatableValues[K] } = {
  currentAction: packet => packet.currentAction,
  currentPacketOwner: packet => packet.currentPacketOwner.id,
  notes: packet => packet.notes,
  isValidWorkPacket: packet => packet.isValidWorkPacket,
  evidenceType: packet => packet.evidenceType.join(","),
  evidenceAttachment: packet => packet.evidenceAttachment,
  disputes: packet => ("disputes" in packet ? packet.disputes : undefined),
};

type Mapper<T> = (old: T, current: T) => T | undefined;

const mappers: { [K in UpdatablePacketKey]?: Mapper<UpdatableValue<K>> } = {
  disputes: (old, current) => {
    if (old.length !== current.length) {
      console.error("[getUpdatedFields] Dispute arrays differ in length");
      return [];
    }
    const updatedDisputes = current.filter((dispute, index) => areValuesDifferent(dispute, old[index]));
    return updatedDisputes.length > 0 ? updatedDisputes : undefined;
  },
};

const areValuesDifferent = (a: unknown, b: unknown): boolean => {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return true;
    return a.some((val, index) => areValuesDifferent(val, b[index]));
  } else if (typeof a === "object" && typeof b === "object" && a && b) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length !== bKeys.length) return true;
    return aKeys.some(key => areValuesDifferent(a[key as keyof typeof a], b[key as keyof typeof b]));
  } else {
    return a !== b;
  }
};

export const getUpdatedFields = (originalWorkPacket: WorkPacket, updatedWorkPacket: WorkPacket): UpdatableValues => {
  const updatedFieldEntries = Object.entries(updateValueGetters)
    .map(([key, getter]) => {
      const oldValue = getter(originalWorkPacket);
      const newValue = getter(updatedWorkPacket);
      if (newValue === undefined) return undefined;

      const isValueDifferent = areValuesDifferent(oldValue, newValue);
      if (!isValueDifferent) return undefined;

      const mapper = mappers[key as UpdatablePacketKey] as Mapper<typeof oldValue>;
      if (mapper) {
        const mappedValue = mapper(oldValue, newValue);
        if (mappedValue === undefined) return undefined;
        return [key, mappedValue];
      } else {
        return [key, newValue] as const;
      }
    })
    .filter(isDefined);
  const updatedFields: UpdatableValues = Object.fromEntries(updatedFieldEntries);
  return updatedFields;
};
