import { Box, Button, CircularProgress, type SxProps, TextField } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";
import { AutocompleteField, StringDateRangePickerField } from "components/UI/Form";
import { Field, Form, useFormikContext } from "formik";
import { type FC } from "react";
import { useUserOptions } from "../useUserOptions";
import { useAccountOptions } from "../WorkPacketsGrid/filters/hooks/useAccountOptions";
import { useVendorOptions } from "../WorkPacketsGrid/filters/hooks/userVendorOptions";
import { initialFilters } from "./case-initial-filters";
import { shortageCaseColMap, ShortageCaseFields, type Option } from "./case-types";
import { caseActionOptions } from "./caseActions";
import { ServerCaseFilters } from "./casesApi";

const filterFieldGridSx: SxProps = {
  gridTemplateColumns: {
    xs: "repeat(1, 1fr)",
    sm: "repeat(2, 1fr)",
    md: "repeat(4, 1fr)",
  },
  gap: 2,
  rowGap: 3,
};

export const ShortageCaseFilterForm: FC = () => {
  const { resetForm, dirty, initialValues, submitForm } = useFormikContext<ServerCaseFilters>();
  const hasNonEmptyValues = initialFilters !== initialValues;
  const clearFilters = () => {
    resetForm({ values: initialValues });
    submitForm();
  };

  const loading = useIsFetching({ queryKey: ["cases"], predicate: query => query.queryKey[3] === "list" }) > 0;

  return (
    <Form>
      <Box display="flex" flexDirection="column" mb={4}>
        <Box display="grid" sx={filterFieldGridSx}>
          <FilterField serverFilter="AmazonCaseId" />
          <FilterField serverFilter="CreatedAt" isDate />
          <FilterField serverFilter="CurrentAction" options={caseActionOptions} />
          <FilterField serverFilter="CurrentCaseOwner" options={useUserOptions(true)} />
          <FilterField serverFilter="StoreName" options={useAccountOptions()} />
          <FilterField serverFilter="VendorName" options={useVendorOptions()} />
        </Box>

        <Box display="flex" justifyContent="flex-end" gap={3}>
          {dirty && (
            <Button style={{ paddingLeft: "12px", paddingRight: "12px" }} onClick={() => resetForm()}>
              Cancel
            </Button>
          )}
          {hasNonEmptyValues && (
            <Button
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
              onClick={() => {
                clearFilters();
                resetForm({ values: initialFilters });
              }}
            >
              Clear
            </Button>
          )}
          <Button type="submit" variant="contained" disabled={!dirty || loading} sx={{ paddingX: "24px" }}>
            <span>Apply</span>
            {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

const FilterField: FC<{ serverFilter: keyof typeof ServerCaseFilters; options?: Option[]; isDate?: boolean }> = ({
  serverFilter,
  options,
  isDate,
}) => {
  if (options) {
    return <AutoCompleteFilterField serverFilter={serverFilter} options={options} />;
  } else if (isDate) {
    return <DateFilterField serverFilter={serverFilter} />;
  } else {
    return <TextFieldFilterField serverFilter={serverFilter} />;
  }
};

interface FilterFieldProps {
  serverFilter: keyof typeof ServerCaseFilters;
  options?: Option[];
}

const TextFieldFilterField: FC<FilterFieldProps> = ({ serverFilter }) => (
  <Field
    name={ServerCaseFilters[serverFilter]}
    label={shortageCaseColMap[ShortageCaseFields[serverFilter]]}
    placeholder={shortageCaseColMap[ShortageCaseFields[serverFilter]]}
    as={TextField}
    variant="outlined"
    size="small"
  />
);

const AutoCompleteFilterField: FC<FilterFieldProps> = ({ serverFilter, options }) => (
  <Field
    name={ServerCaseFilters[serverFilter]}
    label={shortageCaseColMap[ShortageCaseFields[serverFilter]]}
    component={AutocompleteField}
    options={options}
    getOptionLabel={(option: any) => option.title}
    placeholder={shortageCaseColMap[ShortageCaseFields[serverFilter]]}
  />
);

const DateFilterField: FC<FilterFieldProps> = ({ serverFilter }) => (
  <Field
    name={ServerCaseFilters[serverFilter]}
    label={shortageCaseColMap[ShortageCaseFields[serverFilter]]}
    placeholder={shortageCaseColMap[ShortageCaseFields[serverFilter]]}
    component={StringDateRangePickerField}
  />
);
