import { type AxiosRequestConfig } from "axios";
import {
  type AccrualDispute,
  type ChangelogDate,
  type CommandCenterGridRow,
  type ServerAccrualDispute,
  type ServerChangeLogEntry,
  type ServerModelAttachment,
  type ServerWorkPacketTypeMap,
  type WorkPacket,
  type WorkPacketActionName,
  WorkPacketFilter,
  type WorkPacketFilters,
  type WorkPacketServerResponse,
  type WorkPacketsSummaryAggregated,
  type WorkPacketTypeMap,
} from "src/types/work-packets";
import { parseDateRange } from "src/utils/date-range";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import FileDownloadServices from "../../../../Services/FileDownloadServices";
import { mapServerToLocalWorkPackets } from "../mappers";
import mapServerChangeLog from "../mappers/mapServerChangeLog";
import { mapCommandCenterData } from "../mappers/mapServerCommandCenterData";
import { WorkPacketType } from "../WorkPacketType";
import apiClient from "./apiClient";
import { computeSortOption } from "./computeSortOption";

const { downloadFile } = FileDownloadServices;

interface ServerResponse {
  pages: number;
  total_records: number;
  success: boolean;
}

interface FetchUsersResponse extends ServerResponse {
  users: {
    ID: string;
    NICKNAME: string;
    PROFILE_PIC: string;
  }[];
}

interface FetchAccountResponse extends ServerResponse {
  stores: {
    "Account ID": number;
    "Account Name": string;
  }[];
}

interface FetchUserPacketsSummaryResponse {
  num_of_work_packets: number;
  work_packet_red_flag: number;
  work_packets: Record<WorkPacketActionName, number>;
}

interface FetchNewPacketsSummaryResponse {
  num_of_work_packets: number;
  total_stores: number;
  recoverable_amount: {
    total: string;
  };
}

interface FetchAllPacketsSummaryResponse {
  num_of_work_packets: number;
  total_stores: number;
  recoverable_amount: {
    total: string;
    approved: string;
    pending: string;
  };
}

interface FetchChangeLogResponse {
  change_log: ServerChangeLogEntry[];
}

interface FetchCaseChangeLogResponse {
  case_change_log: ServerChangeLogEntry[];
}

type ServerCombinedSummary =
  | FetchUserPacketsSummaryResponse
  | FetchNewPacketsSummaryResponse
  | FetchAllPacketsSummaryResponse;

interface RecoveryStreamFiltersResponse {
  recovery_stream_type: string[];
  recovery_stream_subtype_1: string[];
  recovery_stream_subtype_2: string[];
}

export interface UpdatableValues {
  currentAction?: string;
  currentPacketOwner?: string;
  notes?: string;
  isValidWorkPacket?: boolean;
  evidenceType?: string;
  evidenceAttachment?: string;
  disputes?: AccrualDispute[];
}

export type UpdatablePacketKey = keyof UpdatableValues;
export type UpdatableValue<K extends keyof UpdatableValues> = Required<UpdatableValues>[K];

const localKeyToServerKeyMap: Record<UpdatablePacketKey, string> = {
  currentAction: "CURRENT_ACTION",
  currentPacketOwner: "PACKET_OWNER_ID",
  notes: "NOTES",
  isValidWorkPacket: "VALID",
  evidenceType: "EVIDENCE_TYPE",
  evidenceAttachment: "EVIDENCE_ATTACHMENT",
  disputes: "DISPUTES",
};

type ServerMapper<In, Out = any> = (value: In) => Out;
type ServerValueMappers = { [K in UpdatablePacketKey]?: ServerMapper<UpdatableValue<K>> };
const serverValueMappers: ServerValueMappers = {
  disputes: (disputes): Partial<ServerAccrualDispute>[] =>
    disputes.map(dispute => ({
      ID: dispute.id,
      DISPUTE_ID: dispute.originalDisputeId,
      CONSOLIDATED_DISPUTE_ID: dispute.consolidatedDisputeId,
      CUSTOMER_INVOICE_STATUS: dispute.invoiceStatus,
    })),
};

export type SortDirection = "asc" | "desc";

export interface SortOption {
  key: string;
  direction: SortDirection;
}

export interface FetchWorkPacketListParams<T extends WorkPacketType> {
  type: T;
  page: number;
  pageSize: number;
  filters: WorkPacketFilters;
  sorting?: SortOption;
}

export interface FetchWorkPacketListReturn<T extends WorkPacket> {
  workPackets: T[];
  totalRecords: number;
  totalPages: number;
}

const workPacketTypeToFilterKeysMap: Record<WorkPacketType, WorkPacketFilter[]> = {
  [WorkPacketType.CHARGEBACKS]: [
    WorkPacketFilter.AsinId,
    WorkPacketFilter.RecoveryStream,
    WorkPacketFilter.RecoveryStreamType,
    WorkPacketFilter.RecoveryStreamSubtype1,
    WorkPacketFilter.RecoveryStreamSubtype2,
    WorkPacketFilter.PacketStage,
    WorkPacketFilter.StoreName,
    WorkPacketFilter.VcPoId,
    WorkPacketFilter.CurrentAction,
    WorkPacketFilter.CurrentPacketOwner,
    WorkPacketFilter.VendorName,
    WorkPacketFilter.RedFlags,
    WorkPacketFilter.IssueId,
    WorkPacketFilter.ChargebackCreatedAt,
    WorkPacketFilter.PreCase,
  ],

  [WorkPacketType.SHORTAGES]: [
    WorkPacketFilter.VendorName,
    WorkPacketFilter.StoreName,
    WorkPacketFilter.PacketStage,
    WorkPacketFilter.CurrentAction,
    WorkPacketFilter.ManualFilingUser,
    WorkPacketFilter.RecoveryStreamType,
    WorkPacketFilter.CurrentPacketOwner,
    WorkPacketFilter.RecoveryStream,
    WorkPacketFilter.DisputeCreatedAt,
    WorkPacketFilter.RedFlags,
    WorkPacketFilter.PreCase,
  ],

  [WorkPacketType.ACCRUALS]: [
    WorkPacketFilter.VendorName,
    WorkPacketFilter.StoreName,
    WorkPacketFilter.ManualFilingUser,
    WorkPacketFilter.CurrentPacketOwner,
    WorkPacketFilter.ObarCreatedDate,
    WorkPacketFilter.RecoveryStreamSubtype,
    WorkPacketFilter.AgreementId,
    WorkPacketFilter.InvoiceDate,
    WorkPacketFilter.InvoiceNumber,
    WorkPacketFilter.DisputeCreatedAt,
    WorkPacketFilter.OriginalDisputeId,
    WorkPacketFilter.ConsolidatedDisputeId,
    WorkPacketFilter.CurrentAction,
    WorkPacketFilter.DisputeStatus,
    WorkPacketFilter.WithNotes,
  ],
};

const dateRangeFilters: WorkPacketFilter[] = [
  WorkPacketFilter.DisputeCreatedAt,
  WorkPacketFilter.ChargebackCreatedAt,
  WorkPacketFilter.InvoiceDate,
  WorkPacketFilter.ObarCreatedDate,
];

export const computeFilters = (filters: WorkPacketFilters, workPacketType: WorkPacketType) => {
  const packetTypeFilters = workPacketTypeToFilterKeysMap[workPacketType];
  const computedFilters = Object.fromEntries(
    Object.entries(filters).filter(([key, val]) => {
      if (Array.isArray(val) && !val.length) return false;
      if (val === "") return false;
      return packetTypeFilters.includes(key as WorkPacketFilter);
    }),
  );

  for (const dateRangeFilterName of dateRangeFilters) {
    if (dateRangeFilterName in computedFilters) {
      const dateRange = parseDateRange(computedFilters[dateRangeFilterName]);
      computedFilters[dateRangeFilterName] = dateRange?.map(date => date.format("YYYY-MM-DD")) ?? "";
    }
  }

  return computedFilters;
};

export async function fetchWorkPackets<T extends WorkPacketType>({
  type,
  page,
  pageSize,
  filters,
  sorting,
}: FetchWorkPacketListParams<T>): Promise<FetchWorkPacketListReturn<WorkPacketTypeMap[T]>> {
  type ServerResponse = WorkPacketServerResponse<ServerWorkPacketTypeMap[T]>;
  const computedSortOption = sorting && computeSortOption(sorting, type);
  const response = await apiClient.get<ServerResponse>(`/api/v2/work_packets/${type}`, {
    params: {
      page: page + 1,
      per_page: pageSize,
      filters: JSON.stringify(computeFilters(filters, type)),
      sorting: JSON.stringify(
        computedSortOption ? { [computedSortOption.key]: computedSortOption.direction } : EMPTY_OBJECT,
      ),
    },
    errorMessage: "Error while fetching work packets",
  });

  return {
    workPackets: mapServerToLocalWorkPackets(type, response.data.work_packets),
    totalRecords: response.data.total_records,
    totalPages: response.data.pages,
  };
}

export async function fetchWorkPacketById<T extends WorkPacketType>({
  workPacketType,
  workPacketId,
  signal,
}: {
  workPacketType: T;
  workPacketId: string;
  signal?: AbortSignal;
}): Promise<WorkPacketTypeMap[T]> {
  type ServerResponse = WorkPacketServerResponse<ServerWorkPacketTypeMap[T]>;
  const response = await apiClient.get<ServerResponse>(`/api/v2/work_packets/${workPacketId}/${workPacketType}`, {
    signal,
    errorMessage: "Error while fetching work packet by ID.",
  });
  return mapServerToLocalWorkPackets(workPacketType, response.data.work_packets)[0];
}

export async function fetchAccounts(): Promise<{ value: string; title: string }[]> {
  const response = await apiClient.get("/api/v1/accounts", { errorMessage: "Error while fetching accounts." });
  const data = response.data as FetchAccountResponse;
  return data.stores.map(account => ({
    value: account["Account Name"],
    title: account["Account Name"],
  }));
}

export async function fetchUsers(): Promise<{ value: string; title: string; avatar: string }[]> {
  const response = await apiClient.get("/api/v2/users", { errorMessage: "Error while fetching users." });
  const data = response.data as FetchUsersResponse;

  return data.users.map(user => ({
    value: user.ID,
    title: user.NICKNAME,
    avatar: user.PROFILE_PIC,
  }));
}

const mapUpdatedLocalFieldsToServerParams = (updatedFields: UpdatableValues): Record<string, any> =>
  Object.fromEntries(
    Object.entries(updatedFields as Record<UpdatablePacketKey, UpdatableValues[UpdatablePacketKey]>).map(
      ([_key, val]: [string, UpdatableValues[UpdatablePacketKey]]) => {
        const key = _key as UpdatablePacketKey;
        const mapper = serverValueMappers[key] as ServerMapper<typeof val>;
        return [
          // map from local key to server (all caps) key
          localKeyToServerKeyMap[key] || key,
          val === "" ? null : mapper ? mapper(val) : val,
        ];
      },
    ),
  );

export async function updateWorkPacket<T extends WorkPacketType>({
  workPacketType,
  workPacketId,
  updatedFields,
}: {
  workPacketType: WorkPacketType;
  workPacketId: string;
  updatedFields: UpdatableValues;
}) {
  const serverUpdateParams = mapUpdatedLocalFieldsToServerParams(updatedFields);

  type ServerResponse = WorkPacketServerResponse<ServerWorkPacketTypeMap[T]>;

  const response = await apiClient.put<ServerResponse>(
    `/api/v2/work_packets/${workPacketType}`,
    { work_packet_id: workPacketId, update_params: serverUpdateParams },
    { errorMessage: "Error while updating work packet." },
  );
  return response.data;
}

export async function bulkUpdateWorkPackets({
  workPacketType,
  workPacketIds,
  excludeIds,
  filters,
  userId,
  fields,
}: {
  workPacketType: WorkPacketType;
  workPacketIds: string[];
  excludeIds: string[];
  filters: WorkPacketFilters;
  userId: string;
  fields: Record<string, any>;
}): Promise<any> {
  const updatedFields = Object.entries(fields).reduce(
    (acc, [key, value]) => {
      // Add any other fields that should allow empty strings
      const allowEmptyFields = ["note"];

      if (value !== undefined && value !== null && (value !== "" || allowEmptyFields.includes(key))) {
        const serverKey = localKeyToServerKeyMap[key as UpdatablePacketKey] || key;
        acc[serverKey] = value;
      }
      return acc;
    },
    {} as Record<string, any>,
  );

  const payload = {
    work_packet_ids: workPacketIds,
    excluded_work_packets: excludeIds,
    filters: JSON.stringify(computeFilters(filters, workPacketType)),
    user_id: userId,
    update_params: updatedFields,
  };

  const response = await apiClient.put(`/api/v2/work_packets/${workPacketType}/bulk_update`, payload, {
    errorMessage: "Error while bulk updating.",
  });

  return response.data;
}

/** Used internally to map the server response to the current view, based on the type of summary data. */
function mapServerSummaryToCurrentView(
  summaryData: ServerCombinedSummary,
  type: "user" | "new" | "all",
): WorkPacketsSummaryAggregated {
  let data;
  switch (type) {
    case "user":
      data = summaryData as FetchUserPacketsSummaryResponse;
      return {
        totalWorkPackets: data.num_of_work_packets,
        totalRedFlags: data.work_packet_red_flag,
        workPackets: Object.entries(data.work_packets).map(([action, amount]) => ({
          action: action as WorkPacketActionName,
          amount,
        })),
      };
    case "new":
      data = summaryData as FetchNewPacketsSummaryResponse;
      return {
        totalWorkPackets: data.num_of_work_packets,
        totalStores: data.total_stores,
        recoverableAmount: {
          total: data.recoverable_amount.total,
        },
      };
    case "all":
      data = summaryData as FetchAllPacketsSummaryResponse;
      return {
        totalWorkPackets: summaryData.num_of_work_packets,
        totalStores: data.total_stores,
        recoverableAmount: {
          total: data.recoverable_amount.total,
          approved: data.recoverable_amount.approved,
          pending: data.recoverable_amount.pending,
        },
      };
  }
}

/** Fetches the summary of work packets for the current user. */
export async function fetchUserPacketsSummary(workPacketType: WorkPacketType): Promise<WorkPacketsSummaryAggregated> {
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/summary/my`, {
    errorMessage: "Error while fetching user work packet summary.",
  });

  return mapServerSummaryToCurrentView(response.data.data, "user");
}

/** Fetches the summary of new work packets. */
export async function fetchNewPacketsSummary(workPacketType: WorkPacketType): Promise<WorkPacketsSummaryAggregated> {
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/summary/new`, {
    errorMessage: "Error while fetching new packets summary.",
  });
  return mapServerSummaryToCurrentView(response.data.data, "new");
}

/** Fetches the summary of all work packets. */
export async function fetchAllPacketsSummary(workPacketType: WorkPacketType): Promise<WorkPacketsSummaryAggregated> {
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/summary/all`, {
    errorMessage: "Error while fetching new packets summary.",
  });

  return mapServerSummaryToCurrentView(response.data.data, "all");
}

/** Fetches the change logs for a specific work packet. */
export async function fetchChangeLogs(workPacketId: string, workPacketType: WorkPacketType): Promise<ChangelogDate[]> {
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketId}/change_logs`, {
    errorMessage: "Error while fetching change logs.",
  });

  const data = response.data as FetchChangeLogResponse;

  return mapServerChangeLog({ serverChangeLog: data.change_log, workPacketType });
}

export async function fetchCommandCenterData(
  workPacketType: WorkPacketType,
  signal?: AbortSignal,
): Promise<CommandCenterGridRow[]> {
  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/command_centre`, {
    signal,
    errorMessage: "Error while fetching command centre data.",
  });

  return mapCommandCenterData(response.data);
}

export async function fetchRecoveryStreamFilters(workPacketType: WorkPacketType): Promise<any> {
  const response = await apiClient.get(`/api/v2/${workPacketType}/sub-types`, {
    errorMessage: "Error while fetching fetch recovery stream filters.",
  });
  const data = response.data as RecoveryStreamFiltersResponse;

  const mapToValueTitle = (arr: string[]) => arr.map(value => ({ value, title: value }));

  return {
    recoveryStreamType: mapToValueTitle(data.recovery_stream_type),
    recoveryStreamSubtype1: mapToValueTitle(data.recovery_stream_subtype_1),
    recoveryStreamSubtype2: mapToValueTitle(data.recovery_stream_subtype_2),
  };
}

/** Downloads a CSV file with work packets. */
export async function exportWorkPackets({
  workPacketType,
  userId,
  filters,
}: {
  workPacketType: WorkPacketType;
  userId: string;
  filters: WorkPacketFilters;
}): Promise<void> {
  const params = {
    user_id: userId,
    filters: JSON.stringify(computeFilters(filters, workPacketType)),
  };

  const response = await apiClient.get(`/api/v2/work_packets/${workPacketType}/export`, {
    params,
    responseType: "blob",
    errorMessage: "Error while exporting work packets.",
  });

  downloadFile(response, "work_packets", "csv");
}

export async function getWorkPacketAttachments(
  workPacketId: string,
  signal?: AbortSignal,
): Promise<ServerModelAttachment[]> {
  return apiClient
    .get(`/api/v2/work_packets/${workPacketId}/attachments`, { signal })
    .then(res => res.data.model_attachments);
}

export async function uploadWorkPacketAttachment(
  workPacketId: string,
  file: File,
  onUploadProgress?: AxiosRequestConfig["onUploadProgress"],
): Promise<any> {
  const formData = new FormData();
  formData.set("file", file);
  formData.set("file_name", file.name);
  formData.set("model", "work_packets");
  formData.set("model_id", workPacketId);

  return apiClient.post(`/api/v2/attachments`, formData, {
    adapter: "xhr",
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress,
  });
}

export async function getWorkPacketAttachmentLink(attachmentId: string): Promise<string> {
  const response = await apiClient.get(`/api/v2/attachments/${attachmentId}`);
  return response.data.attachment.url;
}

export async function createAccrualDispute(workPacketId: string): Promise<void> {
  const response = await apiClient.post(`/api/v2/work_packets/${workPacketId}/accruals/dispute`);
  return response.data;
}

/** Fetches the change logs for a specific case. */
export async function fetchCaseChangeLogs(caseId: string, workPacketType: WorkPacketType): Promise<ChangelogDate[]> {
  const response = await apiClient.get(`/api/v2/cases/${caseId}/case_change_logs`, {
    errorMessage: "Error while fetching change logs.",
  });

  const data = response.data as FetchCaseChangeLogResponse;

  return mapServerChangeLog({ serverChangeLog: data.case_change_log, workPacketType });
}
