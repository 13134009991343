import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getAccounts } from "src/queries/accounts";

export const useAccountOptions = () => {
  const { data: accountList } = useQuery({
    queryKey: ["accounts"],
    queryFn: getAccounts,
    staleTime: 30000,
  });

  const accountOptions = useMemo(
    () =>
      (accountList?.stores ?? []).map(account => ({ value: account["Account Name"], title: account["Account Name"] })),
    [accountList],
  );

  return accountOptions;
};
