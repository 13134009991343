export interface Option {
  label?: string;
  title?: string;
  color?: string;
  value: any;
}
export const ShortageCaseFields = {
  ChargeGuardCaseId: "chargeGuardCaseId",
  AmazonCaseId: "amazonCaseId",
  NumOfPackets: "numOfPackets",
  VendorName: "vendorName",
  StoreName: "storeName",
  CurrentCaseOwner: "currentCaseOwner",
  CurrentAction: "currentAction",
  CreatedAt: "createdAt",
  ManualFilingUser: "manualFilingUser",
  RemainingOpenBalance: "remainingOpenBalance",
  ApprovedAmount: "approvedAmount",
} as const;

export type ShortageCaseFieldKeys = keyof typeof ShortageCaseFields;
export type ShortageCaseFields = (typeof ShortageCaseFields)[ShortageCaseFieldKeys];

/**
 * Type for data grid values
 */
export type ShortageCase = {
  [ShortageCaseFields.ChargeGuardCaseId]: string;
  [ShortageCaseFields.AmazonCaseId]: string;
  [ShortageCaseFields.NumOfPackets]: number;
  [ShortageCaseFields.VendorName]: string;
  [ShortageCaseFields.StoreName]: string;
  [ShortageCaseFields.CurrentCaseOwner]: string;
  [ShortageCaseFields.CurrentAction]: string;
  [ShortageCaseFields.CreatedAt]: string;
  [ShortageCaseFields.ManualFilingUser]: string;
  [ShortageCaseFields.RemainingOpenBalance]: number;
  [ShortageCaseFields.ApprovedAmount]: number;
};

/**
 * Column names for data grid
 */
export const shortageCaseColMap: Partial<Record<ShortageCaseFields, string>> = {
  [ShortageCaseFields.ChargeGuardCaseId]: "CG Case ID",
  [ShortageCaseFields.AmazonCaseId]: "Amazon Case ID",
  [ShortageCaseFields.NumOfPackets]: "# of Packets",
  [ShortageCaseFields.VendorName]: "Vendor Name",
  [ShortageCaseFields.StoreName]: "Store (Account Name)",
  [ShortageCaseFields.CurrentCaseOwner]: "Current Case Owner",
  [ShortageCaseFields.CurrentAction]: "Current Action",
  [ShortageCaseFields.CreatedAt]: "Case Creation Date",
  [ShortageCaseFields.ManualFilingUser]: "Manual Filing User",
  [ShortageCaseFields.RemainingOpenBalance]: "Remaining Open Balance",
  [ShortageCaseFields.ApprovedAmount]: "Approved Amount",
} as const;
