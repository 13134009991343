import { Avatar, Box } from "@mui/material";
import { type GridRenderCellParams } from "@mui/x-data-grid";
import { type FC } from "react";
import {
  type WorkPacket,
  type WorkPacketOwner
} from "src/types/work-packets";

export const CurrentOwnerDisplay: FC<GridRenderCellParams<WorkPacket>> = params => {
  const owner = params.value as WorkPacketOwner;
  return (
    <Box display="flex" alignItems="center">
      <Avatar src={owner.avatar} sx={{ width: 32, height: 32 }} />
      <Box ml={1}>{owner.title}</Box>
    </Box>
  );
};
