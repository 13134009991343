import { Box, TextField } from "@mui/material";
import usePermissions from "components/CustomHooks/usePermissions";
import {
  AutocompleteField,
  DatePickerField,
  MultiAutocompleteField,
  SelectField,
  TextAreaField,
} from "components/UI/Form";
import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import { ChargebackWorkPacket, WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketView } from "../../../WorkPacketView";
import { useWorkPacketsContext } from "../../../WorkPacketsContext";
import {
  adminSettableWorkPacketActions,
  settableWorkPacketActions,
  staticFilterOptions,
} from "../../../WorkPacketsGrid/helpers/getStaticFilterOptions";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";
import { useUserOptions } from "../../../useUserOptions";

const evidenceTypes = [
  "ASN",
  "Shipment details",
  "POD",
  "BOL",
  "Purchase Order Details",
  "Invoice Details",
  "Prep Image(s)",
  "Carton Label Image(s)",
  "Weather exception URL",
  "SIOC Certification",
];

const getField = createFieldFn<ChargebackWorkPacket>();

export const WorkPacketInfo: FC = () => {
  const { isAdmin } = usePermissions();
  const { currentView, currentWorkPacketType } = useWorkPacketsContext();
  const actionOptions = isAdmin ? adminSettableWorkPacketActions : settableWorkPacketActions;
  const { setFieldValue } = useFormikContext();
  const userOptions = useUserOptions();

  return (
    <FormFieldsGroup title="Work Packet info" id="work-packet-info">
      <Field
        name={getField("packetId")}
        as={TextField}
        variant="outlined"
        placeholder="Enter Packet ID"
        size="small"
        label="Packet ID"
        disabled
      />

      <Field
        name={getField("packetDate")}
        component={DatePickerField}
        label="Packet Date"
        disabled
      />

      <Field
        name={getField("modifiedAt")}
        as={TextField}
        variant="outlined"
        placeholder="Packet Modified Date"
        size="small"
        label="Packet Modified Date"
        disabled
      />

      <Field
        name={getField("lastModifiedBy.title")}
        component={TextField}
        variant="outlined"
        placeholder="Last Modified By"
        size="small"
        label="Last Modified By"
        disabled
      />

      <Field
        name={getField("closedDate")}
        as={TextField}
        variant="outlined"
        placeholder="Packet Closed Date"
        size="small"
        label="Packet Closed Date"
        disabled
      />

      <Field
        name={getField("vcPoId")}
        as={TextField}
        variant="outlined"
        placeholder="VC PO ID"
        size="small"
        label="VC PO ID"
        disabled
      />

      <Field
        name={getField("currentAction")}
        component={AutocompleteField}
        label="Current Action"
        options={actionOptions[currentWorkPacketType]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        disabled={currentView === WorkPacketView.NewPackets && !isAdmin}
        disableClearable
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={getField("recoveryStreamServer")}
        component={SelectField}
        label="Recovery Stream"
        options={staticFilterOptions[WorkPacketFilter.RecoveryStream]}
        disabled
      />

      <Field
        name={getField("recoveryStreamActivationDate")}
        component={DatePickerField}
        label="Recovery Stream Activation Date"
        disabled
      />

      <Field
        name={getField("recoveryStreamSubtype")}
        as={TextField}
        variant="outlined"
        placeholder="Recovery Stream Subtype"
        size="small"
        label="Recovery Stream Subtype"
        disabled
      />

      <Field
        name={getField("vcFilingUser")}
        as={TextField}
        variant="outlined"
        placeholder="Manual Filing User"
        size="small"
        label="Manual Filing User"
        disabled
      />

      <Field
        name={getField("currentPacketOwner.id")}
        component={AutocompleteField}
        label="Current Packet Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disableClearable={!isAdmin}
      />

      <Field
        name={getField("techUser")}
        as={TextField}
        variant="outlined"
        placeholder="Tech User"
        size="small"
        label="Tech User"
        disabled
      />

      <Field
        name={getField("isValidWorkPacket")}
        value
        component={SelectField}
        label="Is Valid Chargeback"
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />

      <Field
        name={getField("evidenceType")}
        component={MultiAutocompleteField}
        options={evidenceTypes}
        label="Evidence Type(s)"
        setFieldValue={setFieldValue}
      />

      <Field
        name={getField("evidenceAttachment")}
        as={TextField}
        variant="outlined"
        placeholder="Evidence Attachment"
        size="small"
        label="Evidence Attachment"
      />

      <Box sx={{ gridColumn: { xs: "span 1", md: "span 2" } }}>
        <Field name="notes" component={TextAreaField} label="Notes" />
      </Box>
    </FormFieldsGroup>
  );
};
