import "./style.css";

import { type FC, useCallback, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/theme.ts";
import WorkPacketsGrid from "./WorkPacketsGrid/index.tsx";
import { WorkPacketsManager } from "./WorkPacketsManager/index.tsx";
import usePermissions from "components/CustomHooks/usePermissions.js";
import CommandCenter from "./CommandCenter/index.tsx";
import { useWorkPacketsContext, WorkPacketsContext } from "./WorkPacketsContext.tsx";
import { WorkPacketFilter } from "src/types/work-packets";
import { CaseTypeSelector, type CaseView } from "src/components/work-packets/CaseTypeSelector";
import { WorkPacketType } from "./WorkPacketType";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const WorkPacketsInner: FC = () => {
  /*
   * Used to communicate between WorkPacketsManager and WorkPacketsGrid that are on the same level.
   * Flag value doesn't matter, any change will trigger a Summary data refetch.
   */
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const { currentWorkPacketType, currentFilters } = useWorkPacketsContext();
  const isPreCase = currentFilters[WorkPacketFilter.PreCase];

  const navigate = useNavigate();

  const onCaseTypeSelect = useCallback(
    (caseType: CaseView) => {
      navigate(`/user-dashboard/work-packet-cases/${currentWorkPacketType}/${caseType}`);
    },
    [currentWorkPacketType, navigate],
  );

  return (
    <>
      {isPreCase && (
        <Box padding={3}>
          <CaseTypeSelector
            value="pre-case"
            onChange={onCaseTypeSelect}
            showEscalations
            showSettlements={currentWorkPacketType === WorkPacketType.SHORTAGES}
          />
        </Box>
      )}
      <CommandCenter />
      <WorkPacketsManager refetch={triggerRefetch} />
      <WorkPacketsGrid workPacketType={currentWorkPacketType} onDataUpdate={() => setTriggerRefetch(prev => !prev)} />
    </>
  );
};

export const WorkPackets: FC = () => {
  const { permissions } = usePermissions();
  if (!permissions || !permissions.length) return null;

  return (
    <ThemeProvider theme={theme}>
      <WorkPacketsContext>
        <WorkPacketsInner />
      </WorkPacketsContext>
    </ThemeProvider>
  );
};
