import { type GridRenderEditCellParams } from "@mui/x-data-grid";
import { type FC } from "react";
import usePermissions from "src/components/CustomHooks/usePermissions";
import { type WorkPacket, type WorkPacketAction } from "src/types/work-packets";
import EditableAutocompleteCell from "../EditableAutocompleteCell";
import { adminSettableWorkPacketActions, settableWorkPacketActions } from "../helpers/getStaticFilterOptions";

const groupBy = (option: WorkPacketAction) => option.category;

export const CurrentActionEdit: FC<GridRenderEditCellParams<WorkPacket>> = props => {
  const { isAdmin } = usePermissions();
  const actionOptionMap = isAdmin ? adminSettableWorkPacketActions : settableWorkPacketActions;
  const options = actionOptionMap[props.row.workPacketType];
  return <EditableAutocompleteCell {...props} options={options} groupBy={groupBy} />;
};
