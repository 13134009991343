import type { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useQuery, type QueryFunction } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Fragment, type FC, type ReactNode } from "react";
import { abortableDelay } from "src/utils/abortable-timeout";
import type { Correspondence } from "./correspondence-types";
import { MessageDisplay } from "./MessageDisplay";

const fetchCorrespondence: QueryFunction<Correspondence, ["case-correspondence", caseId: string]> = ({
  queryKey: [, _caseId],
  signal,
}) =>
  abortableDelay(1000, signal).then(() => ({
    amazonCaseId: "16545775781",
    primaryEmail: "audit@gochargeguard.com",
    dateOfLastMessage: "Oct 30, 2024 - 10:55AM",
    ourLastMessage: "Oct 28, 2024 - 10:39AM",
    status: "Answered Case",
    messages: [
      {
        id: "04",
        from: "Amazon",
        timestamp: dayjs("2024-10-30T10:55:00"),
        content: `Hello From Amazon,

We hope this mail finds you well and sincerely apologies for the inconveniences this may have caused.

We completed our research of shortage dispute case  16545775781 and would like to confirm that all the shortage deductions have been reversed. You’ll receive the payment in 5 to 6 business days and can review the payment details in your Amazon Vendor Central account by going to Payments > Remittance.

Thank you for your patience and your response is valuable to us, and we look forward to serving you better.

Have a wonderful day!

Thank you!

To view your case details, or respond, please click https://vendorcentral.amazon.com/gp/vendor/members/caselog/case-detail?caseId=16545775781

Please note: this e-mail was sent from an address that cannot accept incoming e-mail. If you require additional support please contact us https://vendorcentral.amazon.com/st/vendor/members/contactusapp

Regards,
Mohammed Faroze A.
Direct Link to the Case: https://vendorcentral.amazon.com/gp/vendor/members/caselog/case-detail?caseId=16545775781`,
      },
      {
        id: "03",
        from: "audit@gochargeguard.com",
        timestamp: dayjs("2024-10-28T10:39:00"),
        content: `Hello Amazon Team,

Thank you for your prompt response. We are eagerly anticipating an update to this case within the provided time frame of 7 business days. However, please be advised that we will be periodically requesting updates on this case.

Your assistance is greatly appreciated.`,
      },
      {
        id: "02",
        from: "Amazon",
        timestamp: dayjs("2024-10-25T11:32:00"),
        content: `Hello from Amazon Finance,

We received your case about the purchase quantity variance (PQV) and are researching the shortages. We’ll respond with an update within 7 business days.

To avoid future shortages, we encourage you to explore the Financial Scorecard to understand the reasons for late or short payments and address any defects. You can find the Financial Scorecard by signing in to your Amazon Vendor account and navigating to Payments > Financial Scorecard. If you have other questions about PQV shortages, training is available at https://tiny.amazon.com/xoh17t94/vendamazhzvendmembsupptrai.

Thank you for your patience while we research your case.

Thank you,
Amazon Finance`,
      },
      {
        id: "01",
        from: "audit@gochargeguard.com",
        timestamp: dayjs("2024-10-25T11:32:00"),
        content: `Dispute Justification Summary:

 Greetings Amazon - We determined this shortage claim invalid following our investigation. These ASIN#’s were shipped/delivered in full on ARN# 29755971231 , 29731808761 , 29677440351 , 29658820621 , 29523615251 ASN# 41062439362 , 41049141712 , 41016722862 , 41004741972 , 40928879782. The root cause appears to be an Amazon receiving issue. We request reimbursement as soon as possible. Thank you!

Here are the details you need for investigating this specific case:
Dispute ID : DSPT11950441567
Invoice Number : SIP-521608SCR
Purchase Order Number: 4ABU9NKO

Dispute Details:
{
  "disputeIds": "DSPT11950441567",
  "invoiceNumbers": "SIP-521608SCR",
  "poNumbers": "4ABU9NKO",
  "details": [
    {
      "invoiceNumber": "SIP-521608SCR",
      "poNumbers": "4ABU9NKO",
      "disputeIds": "",
      "disputeQuantity": 1,
      "disputeAmount": 7.94,
      "vendorCode": "TJERN"
    }
  ]
}`,
      },
    ],
  }));

const gridSx: SxProps = {
  gridTemplateColumns: {
    xs: "repeat(1, 1fr)",
    sm: "repeat(2, 1fr)",
    md: "repeat(4, 1fr)",
  },
  gap: 2,
  rowGap: 3,
  alignItems: "center",
};

export const CaseCorrespondence: FC<{ caseId: string }> = ({ caseId }) => {
  const query = useQuery({
    queryFn: fetchCorrespondence,
    queryKey: ["case-correspondence", caseId],
  });

  if (query.isPending)
    return (
      <Box sx={{ marginBlock: 8, display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (query.isError) return <span>Failed to load correspondence for case ID "{caseId}"</span>;

  return (
    <Box display="flex" flexDirection="column" gap={4} padding={4}>
      <Section title="Case Info">
        <Box display="grid" sx={gridSx}>
          <Field label="Amazon Case ID" value={query.data.amazonCaseId} />
          <Field label="Primary Email" value={query.data.primaryEmail} />
          <Field label="Date of Last Message" value={query.data.dateOfLastMessage} />
          <Field label="Our Last Message" value={query.data.ourLastMessage} />
          <Field label="Status" value={query.data.status} />
          <Link
            href={`https://vendorcentral.amazon.com/cu/case-dashboard/view-case?caseID=${query.data.amazonCaseId}`}
            target="_blank"
            color="#F7104D"
            style={{ gridColumnEnd: "span 2" }}
          >
            View your case and request log
          </Link>
        </Box>
      </Section>
      <Section title="Correspondence">
        <Box display="flex" flexDirection="column">
          {query.data.messages.map((message, index) => (
            <Fragment key={message.id}>
              <MessageDisplay message={message} />
              {index < query.data.messages.length - 1 && <hr style={{ marginBlock: "2rem" }} />}
            </Fragment>
          ))}
        </Box>
      </Section>
    </Box>
  );
};

const Field: FC<{ value: string; label: string }> = ({ value, label }) => (
  <TextField value={value} label={label} placeholder={label} variant="outlined" size="small" />
);

const Section: FC<{ title: string; children: ReactNode }> = ({ title, children }) => (
  <Box border="1px solid rgba(0, 0, 0, 0.4)" borderRadius="8px" padding="1.5rem">
    <Typography variant="subtitle1" color="#F7104D" fontWeight={500} marginBottom={3}>
      {title}
    </Typography>
    {children}
  </Box>
);
