import dayjs, { type Dayjs } from "dayjs";
import { attempt } from "./attempt";

export type MaybeIncompleteDateRange = [start: Dayjs | null, end: Dayjs | null];
export type DateRange = [start: Dayjs, end: Dayjs];

export const EMPTY_DATE_RANGE: MaybeIncompleteDateRange = [null, null];

const dateRangeRegex = /(\d{4}-\d{1,2}-\d{1,2})?-(\d{4}-\d{1,2}-\d{1,2})?/;

export const parseDateRange = (dateString: string): DateRange | undefined =>
  attempt(
    () =>
      dateRangeRegex
        .exec(dateString)
        ?.slice(1, 3)
        .map(date => dayjs(date)) as DateRange | undefined,
    undefined,
  );

export const stringifyDateRange = (dateRange: MaybeIncompleteDateRange): string =>
  {
    if (dateRange.every(value => value === null)) return "";
    return dateRange.map(date => (date ? date.format("YYYY-MM-DD") : "")).join("-");
  };
