import type { CommandCenterEntry, CommandCenterGridRow, CommandCenterResponse } from "src/types/work-packets";

const mapEntryToRows = (
  entryKey: string,
  entry: CommandCenterEntry,
  prevHierarchy?: string[],
): CommandCenterGridRow[] => {
  const hierarchy = prevHierarchy ? [...prevHierarchy, entryKey] : [entryKey];
  const row: CommandCenterGridRow = {
    hierarchy,
    id: JSON.stringify(hierarchy),
    openCount: entry.count,
    openAmount: String(entry.total_amount),
    redFlags: entry.red_flag_count,
    expiredCount: entry.expired_count,
    expiredAmount: entry.expired_amount != undefined ? String(entry.expired_amount) : undefined,
  };
  if (!entry.child) return [row];
  return [row, ...Object.entries(entry.child).flatMap(([key, entry]) => mapEntryToRows(key, entry, hierarchy))];
};

export const mapCommandCenterData = ({ data }: CommandCenterResponse): CommandCenterGridRow[] =>
  Object.entries(data).flatMap(([key, entry]) => mapEntryToRows(key, entry));
