import { type QueryFunction } from "@tanstack/react-query";
import { http } from "src/axios";

export interface VendorListResponse {
  pages: number;
  success: boolean;
  total_records: number;
  vendors: Vendor[];
}

export interface Vendor {
  AGREEMENT_SIGNED_DATE: string;
  BILLING_EMAIL: string | null;
  BILLING_ENTITY: string | null;
  BILLING_NAME: string | null;
  CURRENT_RECOVERY: string | null;
  ENGAGEMENT_FEE: string | null;
  FINAL_COMMISSION_RATE: string | null;
  INITIAL_COMMISSION_RATE: string | null;
  KICK_OFF: string | null;
  NOTES: string | null;
  PHONE: string | null;
  RECOVERY_THRESHOLD: string | null;
  REFERRALS: VendorReferral[];
  SPECIFIC_POINT_OF_CONTACT_EMAIL: string | null;
  SPECIFIC_POINT_OF_CONTACT_NAME: string | null;
  TIER: Tier | null;
  VENDOR_CREATED: string | null;
  VENDOR_ID: number;
  VENDOR_NAME: string;
  VENDOR_TYPE: VendorType;
}

export interface VendorReferral {
  COMMISSION_ELIGIBILITY_END_AT: string | null;
  COMMISSION_RATE: number | null;
  CUSTOM_COMMISSION_RATE: string | null;
  PARTNER_ID: string | null;
  PARTNER_TYPE: PartnerType | null;
  PAYMENT_METHOD: PaymentMethod | null;
  REFERRAL_ID: string;
  REFERRAL_PARTNER_ID: number;
  REFERRAL_PARTNER_NAME: string;
}

export enum PartnerType {
  ChineseAffiliate = "Chinese Affiliate",
  Other = "Other",
  StandardAffiliate = "Standard Affiliate",
  StrategicPartnership = "Strategic Partnership",
}

export enum PaymentMethod {
  DirectDepositWire = "Direct Deposit/Wire",
  Other = "Other",
  Partnerstack = "Partnerstack",
}

export enum Tier {
  Smb = "SMB",
  Enterprise = "Enterprise",
  MidMarket = "Mid Market",
}

export enum VendorType {
  Chinese = "Chinese",
  Standard = "Standard",
}

export const getVendorList: QueryFunction<Vendor[], ["vendors"]> = async ({ signal }) => {
  const response = await http.get<VendorListResponse>("/api/v2/vendors", { signal });
  return response.data.vendors;
};
