import { ArrowForwardRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { type GridRenderCellParams } from "@mui/x-data-grid";
import { type FC, useContext } from "react";
import { type WorkPacket } from "src/types/work-packets";
import { WorkPacketPopupContext } from "../WorkPacketPopupContext";

export const DetailsButton: FC<GridRenderCellParams<WorkPacket>> = params => {
  const setCurrentWorkPacketId = useContext(WorkPacketPopupContext);
  return (
    <Button
      variant="outlined"
      endIcon={<ArrowForwardRounded />}
      onClick={() => setCurrentWorkPacketId(params.row.packetId)}
    >
      Details
    </Button>
  );
};
