import { type StagefulAction, type StagelessAction, type StageName, stageNames } from "src/types/work-packets";

export type ParsedAction =
  | {
      stage: StageName;
      action: StagefulAction;
    }
  | {
      action: StagelessAction;
    };

export const stageNamePrefixes = stageNames.map(n => `${n}_` as const);

export const parseAction = (serverActionName: string): ParsedAction => {
  if (stageNamePrefixes.some(prefix => serverActionName.startsWith(prefix))) {
    const [stage, ...actionParts] = serverActionName.split("_");
    return {
      stage: stage as StageName,
      action: actionParts.join("_") as StagefulAction,
    };
  } else {
    return {
      action: serverActionName as StagelessAction,
    };
  }
};
