/**
 * This custom hook manages the fetching, filtering, sorting, and paginating of work packets.
 * Note: This hook is designed to work with MUI's DataGrid component.
 */

import { useState, useEffect, useCallback, useMemo } from "react";
import {
  type GridFilterModel,
  type GridRowSelectionModel,
  type GridSortModel,
} from "@mui/x-data-grid";
import {
  type WorkPacketTypeMap,
  WorkPacketFilter,
  type WorkPacketFilters,
} from "src/types/work-packets";
import { useAuth0 } from "@auth0/auth0-react";
import { getUpdatedFields } from "./util/getUpdatedFields";
import { WorkPacketView } from "./WorkPacketView";
import * as WorkPacketsAPI from "./api/workPacketsAPI";
import debounce from "lodash/debounce";
import toast from "react-hot-toast";
import { useWorkPacketsContext } from "./WorkPacketsContext";
import { WorkPacketType } from "./WorkPacketType";
import { useQueryClient } from "@tanstack/react-query";

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface UseWorkPacketsReturnType<T extends WorkPacketType> {
  /** Grid rows */
  data: WorkPacketTypeMap[T][];
  /** Pagination model: current page and page size */
  paginationModel: PaginationModel;
  /** Total number of rows (not just the current page) */
  total: number;
  /** Loading state while fetching data */
  loading: boolean;
  /** Filter values */
  filters: WorkPacketFilters;
  /** Update filter values */
  setFilters: (filters: WorkPacketFilters) => void;
  /** Selected rows (row IDs) including all pages */
  rowSelection: GridRowSelectionModel;
  /** Update selected rows */
  setRowSelection: (newSelection: GridRowSelectionModel) => void;
  /** Keeps track of whether user clicked "Select All" */
  selectAllEnabled: boolean;
  /** Rows that should be excluded from selection when "Select All" is clicked */
  excludeWorkPacketIds: string[];
  /** Callbacks for grid events */
  callbacks: {
    /** Triggered on any pagination change */
    onPaginationModelChange: (model: {
      page: number;
      pageSize: number;
    }) => void;
    /** Triggered on any filter change */
    onFilterModelChange: (filterModel: GridFilterModel) => void;
    /** Triggered on any sort change */
    onSortModelChange: (sortModel: GridSortModel) => void;
    /** Triggered when a row is updated */
    processRowUpdate: (updatedRow: any, originalRow: any) => any;
    /** Triggered when a row update fails */
    onProcessRowUpdateError: (error: any) => void;
    /** Triggered when row selection changes */
    onRowSelectionModelChange: (newSelection: GridRowSelectionModel) => void;
  };

  /** Force trigger a fetch of work packets */
  triggerFetchWorkPackets: () => void;

  /** Set the row update signal */
  rowUpdateSignal: boolean;

  /** Call API to export work packets */
  handleExport: () => void;

  /** Export status */
  isExporting: boolean;
}

const computeFilters = (filters: WorkPacketFilters, view: WorkPacketView, userNickname?: string) => {
  const currentPacketOwnerMap: Record<WorkPacketView, string> = {
    [WorkPacketView.MyPackets]: userNickname || "",
    [WorkPacketView.NewPackets]: "UNASSIGNED",
    [WorkPacketView.AllPackets]: filters[WorkPacketFilter.CurrentPacketOwner],
  };
  return ({
    ...filters,
    /* Force current packet owner filter if the view is "My Packets" */
    [WorkPacketFilter.CurrentPacketOwner]: currentPacketOwnerMap[view],
  });
};

const getSortOption = (sortModel: GridSortModel): WorkPacketsAPI.SortOption | undefined => {
  if (!sortModel[0]) return;
  const { field: key, sort: direction } = sortModel[0];
  if (!direction) return;
  return { key, direction };
}

/**
 * Custom hook to manage fetching, filtering, sorting, and paginating work packets.
 */
export const useWorkPacketsGrid = <T extends WorkPacketType>(workPacketType: T): UseWorkPacketsReturnType<T> => {
  type LocalWorkPacket = WorkPacketTypeMap[T];
  const { user } = useAuth0();
  const [data, setData] = useState<LocalWorkPacket[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const { currentView: view, currentFilters, setFilters } = useWorkPacketsContext();

  const computedFilters = useMemo(
    () => computeFilters(currentFilters, view, user?.sub),
    [view, currentFilters, user?.sub],
  );

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([]);
  const [excludedFromSelection, setExcludedFromSelection] = useState<string[]>(
    []
  );
  const [selectAllMode, setSelectAllMode] = useState<
    /** TODO: Could be simplified to keep select-all only  */
    "select-all" | "deselect-all" | null
  >(null);

  const queryClient = useQueryClient();

  /*
   * Used to let the component know that the row has been updated.
   * Usefull for sharing info between non-nested components.
   * Flag value doesn't matter.
   */
  const [rowUpdateSignal, setRowUpdateSignal] = useState(false);

  const fetchWorkPacketsData = useCallback(
    async (
      paginationModel: PaginationModel,
      filters: WorkPacketFilters,
      sorting: GridSortModel
    ) => {
      try {
        setLoading(true);
        const data = await WorkPacketsAPI.fetchWorkPackets({
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          filters,
          sorting: getSortOption(sorting),
          type: workPacketType,
        });
        data.workPackets.forEach(packet => {
          queryClient.setQueryData(["work-packet", packet.workPacketType, "details", packet.packetId], packet);
        });

        setData(data.workPackets as LocalWorkPacket[]);
        setTotal(data.totalRecords);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    },
    [queryClient, view, workPacketType],
  );

  /* Preventing multiple requests especially on initial load */
  const debouncedFetchWorkPacketsData = useCallback(
    debounce(
      (paginationModel, filters, sorting) => {
        fetchWorkPacketsData(paginationModel, filters, sorting);
      },
      300,
      { leading: true, trailing: false }
    ),
    [fetchWorkPacketsData]
  );

  useEffect(() => {
    debouncedFetchWorkPacketsData(
      paginationModel,
      computedFilters,
      sortModel,
    );
  }, [paginationModel, computedFilters, fetchWorkPacketsData, view, sortModel]);

  useEffect(() => {
    if (selectAllMode === "select-all") {
      const currentPageRowIds = data.map((row) => row.packetId);

      // Select all rows on the current page excluding the ones that are excluded
      setRowSelection((prevSelection) => [
        ...prevSelection,
        ...currentPageRowIds.filter(
          (id) =>
            !prevSelection.includes(id) && !excludedFromSelection.includes(id)
        ),
      ]);
    } else if (selectAllMode === "deselect-all") {
      setRowSelection([]);
      setExcludedFromSelection([]);
    } else {
      setExcludedFromSelection([]);
    }
  }, [paginationModel.page, selectAllMode, data]);

  const triggerFetchWorkPackets = () => {
    fetchWorkPacketsData(
      paginationModel,
      computedFilters,
      sortModel,
    );
  };

  const handlePaginationModelChange = useCallback(
    (model: { page: number; pageSize: number }) => {
      setPaginationModel(model);
    },
    []
  );

  const handleRowUpdate = async (
    updatedRow: LocalWorkPacket,
    originalRow: LocalWorkPacket,
  ) => {
    try {
      setLoading(true);

      const updatedFields = getUpdatedFields(originalRow, updatedRow);
      if (Object.keys(updatedFields).length === 0) return originalRow;

      await WorkPacketsAPI.updateWorkPacket({
        workPacketType: workPacketType,
        workPacketId: updatedRow.packetId,
        updatedFields,
      });
      queryClient.invalidateQueries({
        queryKey: ["work-packets", updatedRow.workPacketType, "details", updatedRow.packetId],
      });

      const updatedData = data.map(row => row.packetId === originalRow.packetId ? updatedRow : row);

      setData(updatedData);
      setRowUpdateSignal((prev) => !prev);
      return updatedRow;
    } catch (error) {
      console.error("Error updating row:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowError = (error: any) => {
    console.error("Error updating row", error);
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    console.info("Grid Filter change", { filterModel });
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleRowSelection = (newSelection: GridRowSelectionModel) => {
    /* TODO: THINK ABOUT CORNER CASES */

    /* Check if the selection is a bulk selection */
    const totalChanges = Math.abs(newSelection.length - rowSelection.length);
    const isBulkSelection = totalChanges > 1;
    if (isBulkSelection) {
      const isSelectAll = newSelection.length > rowSelection.length;
      setSelectAllMode(isSelectAll ? "select-all" : "deselect-all");

      if (!isSelectAll) {
        triggerFetchWorkPackets();
        setExcludedFromSelection([]);
        setSelectAllMode(null);
        setRowSelection([]);
        return;
      }

      console.info(
        "Bulk selection",
        isSelectAll ? "Select All" : "Deselect All"
      );
    }

    // If selected all check for exclusion:
    if (selectAllMode === "select-all") {
      const excludedIds = data
        .filter((row) => !newSelection.includes(row.packetId))
        .map((row) => row.packetId);

      // Include unique IDs to be added to exclude list
      setExcludedFromSelection((prev) => {
        const uniqueIds = Array.from(new Set([...prev, ...excludedIds]));
        return uniqueIds;
      });
    }

    setRowSelection(newSelection);
  };

  const handleExport = useCallback(async () => {
    // Limiting export to 100k rows
    if (total >= 100000) {
      toast.error(
        "Export row limit of 100k exceeded. Please apply some filters to the data set and try again.",
        {
          duration: 4000,
        }
      );
      return;
    }

    if (!user || !user.sub) return;

    try {
      setIsExporting(true);
      await WorkPacketsAPI.exportWorkPackets({
        workPacketType: workPacketType,
        userId: user.sub,
        filters: computedFilters,
      });

      toast.success("Export completed!");
    } catch (error) {
      console.error("Error while exporting:", error);
      toast.error("Error exporting work packets");
    } finally {
      setIsExporting(false);
    }
  }, [user, computedFilters, total, workPacketType]);

  return {
    data,
    paginationModel,
    filters: computedFilters,
    total,
    loading,
    rowSelection,
    setRowSelection,
    setFilters,
    selectAllEnabled: selectAllMode === "select-all",
    excludeWorkPacketIds: excludedFromSelection,
    triggerFetchWorkPackets,
    rowUpdateSignal,
    handleExport,
    isExporting,
    callbacks: {
      onPaginationModelChange: handlePaginationModelChange,
      onFilterModelChange: onFilterChange,
      onSortModelChange: handleSortModelChange,
      processRowUpdate: handleRowUpdate,
      onProcessRowUpdateError: handleRowError,
      onRowSelectionModelChange: handleRowSelection,
    },
  };
};
