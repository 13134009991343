import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  CircularProgress,
  Box,
} from "@mui/material";
import { FC, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  WorkPacketFilter,
  WorkPacketFilters,
  WorkPacketOwner,
} from "src/types/work-packets";
import { AutocompleteField, SelectField, TextAreaField } from "components/UI/Form";
import { Formik, Field, Form } from "formik";
import { adminSettableWorkPacketActions, settableWorkPacketActions } from "./helpers/getStaticFilterOptions";
import toast from "react-hot-toast";
import * as WorkPacketsAPI from "../api/workPacketsAPI";
import { useWorkPacketsContext } from "../WorkPacketsContext";
import usePermissions from "src/components/CustomHooks/usePermissions";
import { WorkPacketType } from "../WorkPacketType";
import { useUserOptions } from "../useUserOptions";
import { useQueryClient } from "@tanstack/react-query";

interface BulkUpdatePopup {
  workPacketIds?: string[];
  excludeIds?: string[];
  filters: WorkPacketFilters;
  selectedAll?: boolean;
  open: boolean;
  allowEditPacketActionAndNotes: boolean;
  onClose: (success?: boolean) => void;
}

export const BulkUpdatePopup: FC<BulkUpdatePopup> = ({
  workPacketIds,
  excludeIds,
  open,
  filters,
  selectedAll,
  allowEditPacketActionAndNotes,
  onClose,
}) => {
  const { user } = useAuth0();
  const { currentWorkPacketType } = useWorkPacketsContext();
  const [loading, setLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { isAdmin } = usePermissions();
  const actionOptions = isAdmin ? adminSettableWorkPacketActions : settableWorkPacketActions;

  const initialValues = {
    [WorkPacketFilter.CurrentAction]: "",
    [WorkPacketFilter.CurrentPacketOwner]: "",
    isValidWorkPacket: null,
    notes: "",
  };

  const handleSubmit = async (values: Record<string, any>) => {
    if (!user?.sub) return;
    try {
      setLoading(true);

      await WorkPacketsAPI.bulkUpdateWorkPackets({
        workPacketType: currentWorkPacketType,
        userId: user.sub,
        workPacketIds: selectedAll ? [] : workPacketIds || [],
        excludeIds: selectedAll ? excludeIds || [] : [],
        filters,
        fields: values,
      });
      queryClient.invalidateQueries({ queryKey: ["work-packets", currentWorkPacketType] });

      toast.success("Work Packets updated successfully");
      onClose(true); // `true` invalidates work packet list data
    } catch (error) {
      console.error("Error in bulk update work packets:", error);
      toast.error("Error updating work packets");
    } finally {
      setLoading(false);
    }
  };

  const currentOwnerOptions = useUserOptions();

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
      <DialogTitle>Bulk Actions</DialogTitle>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue, dirty }) => (
          <Form>
            <DialogContent>
              <Divider />

              <Box display="flex" flexDirection="column" mb={4}>
                <Box display="flex" gap={4} flexDirection="column">
                  <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                    <Field
                      name={WorkPacketFilter.CurrentAction}
                      component={AutocompleteField}
                      label="Current Action"
                      options={actionOptions[currentWorkPacketType]}
                      groupBy={(option: any) => option.category}
                      getOptionLabel={(option: any) => option.title}
                      placeholder="Current Action"
                      setFieldValue={setFieldValue}
                      disabled={loading || !allowEditPacketActionAndNotes}
                      workPacketType={currentWorkPacketType}
                    />
                  </Box>

                  <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                    <Field
                      name={WorkPacketFilter.CurrentPacketOwner}
                      component={AutocompleteField}
                      label="Current Packet Owner"
                      options={currentOwnerOptions}
                      getOptionLabel={(option: WorkPacketOwner) => option.title}
                      placeholder="Current Packet Owner"
                      setFieldValue={setFieldValue}
                      disabled={loading}
                    />
                  </Box>

                  {currentWorkPacketType === WorkPacketType.CHARGEBACKS && (
                    <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                      <Field
                        name="isValidWorkPacket"
                        value
                        component={SelectField}
                        label="Is Valid Chargeback?"
                        options={[
                          { value: null, label: "(unchanged)" },
                          { value: true, label: "Yes" },
                          { value: false, label: "No" },
                        ]}
                      />
                    </Box>
                  )}

                  <Field
                    name="notes"
                    component={TextAreaField}
                    label="Notes"
                    disabled={loading || !allowEditPacketActionAndNotes}
                  />
                </Box>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                className="contained-reversed"
                variant="contained"
                onClick={() => onClose()}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!dirty || loading}
                sx={{ paddingX: "24px" }}
              >
                <span>Save Changes</span>
                {loading && (
                  <CircularProgress
                    size={14}
                    style={{ marginLeft: "12px", color: "#101828" }}
                  />
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
