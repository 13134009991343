import { useQuery } from "@tanstack/react-query";
import { getCommandCenterData } from "../../../queries/command-center";
import { WorkPacketType } from "../../../WorkPacketType";
import { EMPTY_ARRAY } from "src/utils/empty-values";

export const useAccrualSubtypes = () => {
  const query = useQuery({
    queryFn: getCommandCenterData,
    queryKey: ["work-packets", WorkPacketType.ACCRUALS, "command-center"],
    staleTime: 30000,
  });

  const subtypes = query.data
    ?.filter(row => row.hierarchy.length === 2)
    .map(row => {
      const subtype = row.hierarchy[1];
      return { value: subtype, title: subtype };
    });

  return subtypes ?? EMPTY_ARRAY;
};
