import { Box, CircularProgress } from "@mui/material";
import TimelineDate from "../../../WorkPacketDetailsPopup/ChangeLog/TimelineDate";
import { type FC, useCallback, useEffect, useState } from "react";
import * as WorkPacketsAPI from "../../../api/workPacketsAPI";
import debounce from "lodash.debounce";
import { type ChangelogDate } from "src/types/work-packets";
import { type WorkPacketType } from "../../../WorkPacketType";

interface ChangeLogProps {
  caseId: string;
  caseType: WorkPacketType,
}

export const CaseChangeLog: FC<ChangeLogProps> = ({ caseId, caseType }) => {
  const [changeLogs, setChangeLogs] = useState<ChangelogDate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchChangeLog = useCallback(
    debounce(
      async () => {
        setLoading(true);
        try {
          const data = await WorkPacketsAPI.fetchCaseChangeLogs(caseId, caseType);
          setChangeLogs(data);
        } catch (error) {
          console.error("Error in fetchChangeLog:", error);
        } finally {
          setLoading(false);
        }
      },
      300,
      { leading: true, trailing: false }
    ),
    [caseId, caseType],
  );

  useEffect(() => {
    fetchChangeLog();
  }, [caseId]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box padding="32px">
        {changeLogs.map((dateGroup, index) => (
          <TimelineDate key={index} {...dateGroup} />
        ))}
      </Box>
    </Box>
  );
};
