import TextField from "@mui/material/TextField";
import { Field } from "formik";
import { type FC } from "react";
import { type AccrualWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";

const getField = createFieldFn<AccrualWorkPacket>();

export const FinalRecoveryResults: FC = () => {
  return (
    <FormFieldsGroup id="final-recovery-results-inner">
      <Field
        name={getField("sumOfAllReversalPayments")}
        as={TextField}
        variant="outlined"
        size="small"
        label="SUM of all REVERSAL Payments"
        placeholder="-"
        disabled
      />
      <Field
        name={getField("recoveryRate")}
        as={TextField}
        variant="outlined"
        size="small"
        label="Recovery Rate"
        placeholder="-"
        disabled
      />
    </FormFieldsGroup>
  );
};
