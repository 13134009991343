import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import usePermissions from "components/CustomHooks/usePermissions";
import { useMemo } from "react";
import { getUsersWithPermissions, type User } from "src/queries/users";

const createUserOption = (user: User) => ({
  id: user.ID,
  value: user.ID,
  title: user.NICKNAME,
  avatar: user.PROFILE_PIC,
});

export const useUserOptions = (showAll?: boolean) => {
  const { user } = useAuth0();
  const { isAdmin } = usePermissions();

  const { data: usersList } = useQuery({
    queryKey: ["users"],
    queryFn: getUsersWithPermissions,
    staleTime: 30000,
  });

  const userOptions = useMemo(() => {
    if (!user?.sub || !usersList) return [];
    if (!isAdmin && !showAll) return usersList.filter(u => u.ID === user.sub).map(createUserOption);
    return usersList.map(createUserOption);
  }, [isAdmin, user?.sub, usersList]);

  return userOptions;
};
