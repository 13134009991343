import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getAuditAccounts } from "src/queries/audit-accounts";

export const useAuditAccountOptions = () => {
  const { data: auditAccounts } = useQuery({
    queryKey: ["audit-accounts"],
    queryFn: getAuditAccounts,
    staleTime: 30000,
  });

  const auditAccountOptions = useMemo(
    () => (auditAccounts ?? []).map(name => ({ value: name, title: name })),
    [auditAccounts],
  );

  return auditAccountOptions;
};
