import type { QueryFunction } from "@tanstack/react-query";
import { http } from "src/axios";
import { WorkPacketType } from "../WorkPacketType";

export interface SubtypeOption {
  value: string;
  title: string;
}

const createDropdownOption = (value: string) => ({ value, title: value });

interface ChargebackSubtypesResponse {
  recovery_stream_type: string[];
  recovery_stream_subtype_1: string[];
  recovery_stream_subtype_2: string[];
}

export interface ChargebackSubtypes {
  recoveryStreamType: SubtypeOption[];
  recoveryStreamSubtype1: SubtypeOption[];
  recoveryStreamSubtype2: SubtypeOption[];
}

export const getChargebackSubtypes: QueryFunction<ChargebackSubtypes, ["chargeback-subtypes"]> = async ({ signal }) => {
  const response = await http.get<ChargebackSubtypesResponse>(`/api/v2/${WorkPacketType.CHARGEBACKS}/sub-types`, {
    signal,
  });
  const data = response.data;

  return {
    recoveryStreamType: data.recovery_stream_type.map(createDropdownOption),
    recoveryStreamSubtype1: data.recovery_stream_subtype_1.map(createDropdownOption),
    recoveryStreamSubtype2: data.recovery_stream_subtype_2.map(createDropdownOption),
  };
};
