import { useAuth0 } from "@auth0/auth0-react";
import { FilterList } from "@mui/icons-material";
import { Box, Button, Collapse, Divider, Tab, Tabs } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { DataGridPro, type GridPaginationModel, type GridSortModel } from "@mui/x-data-grid-pro";
import { type QueryFunction, useQuery } from "@tanstack/react-query";
import { CaseTypeSelector } from "components/work-packets/CaseTypeSelector";
import { Formik } from "formik";
import { type FC, useCallback, useMemo, useState } from "react";
import theme from "src/styles/theme";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import useColumnVisibility from "src/utils/grid/useColumnVisibility";
import type { SortOption } from "../api/workPacketsAPI";
import { initialFilters } from "./case-initial-filters";
import { ShortageCaseFilterForm } from "./CaseFilters";
import { shortageCasesColumns } from "./CaseGridColumns";
import { fetchCases, type FetchCasesArgs, type FetchCasesResponse, ServerCaseFilters } from "./casesApi";
import CommandCenter from "src/pages/UserDashboard/WorkPackets/Cases/CommandCenter";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/CaseType";
import { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType";

const getSortOption = (sortModel: GridSortModel): SortOption | undefined => {
  if (!sortModel[0]) return;
  const { field: key, sort: direction } = sortModel[0];
  if (!direction) return;
  return { key, direction };
};

enum ViewTab {
  AllCases = "All Cases",
  NewCases = "New Cases",
  MyCases = "My Cases",
}

const getShortageCasesData: QueryFunction<
  FetchCasesResponse,
  ["cases", "shortages", "escalations", "list", FetchCasesArgs]
> = async ({ queryKey: [, , , , fetchCaseArgs], signal }) => fetchCases(fetchCaseArgs, signal);

export const Cases: FC<{ caseType: CaseType }> = ({ caseType }) => {
  const { user } = useAuth0();
  const [currentFilters, setFilters] = useState(initialFilters);
  const [showFilters, setShowFilters] = useState(true);
  const [currentTab, setTab] = useState<ViewTab>(ViewTab.AllCases);
  const [sortingModel, setSortModel] = useState<GridSortModel>([]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const { page, pageSize } = paginationModel;

  const updateTab = (tab: ViewTab) => {
    const initialCaseOwnerMap: Record<ViewTab, string> = {
      [ViewTab.AllCases]: "",
      [ViewTab.NewCases]: "UNASSIGNED",
      [ViewTab.MyCases]: user!.sub!,
    };
    setFilters({
      ...currentFilters,
      [ServerCaseFilters.CurrentCaseOwner]: initialCaseOwnerMap[tab],
    });
    setTab(tab);
  };

  const fetchParams = useMemo<FetchCasesArgs>(() => {
    const sorting = getSortOption(sortingModel);
    return { page, pageSize, filters: currentFilters, sorting };
  }, [page, pageSize, currentFilters, sortingModel]);

  const query = useQuery({
    queryFn: getShortageCasesData,
    queryKey: ["cases", "shortages", "escalations", "list", fetchParams],
    staleTime: 30000,
  });

  const toggleFilters = () => setShowFilters(prev => !prev);
  const applyFilters = useCallback((values: ServerCaseFilters) => setFilters(values), [setFilters]);

  const { columnVisibilityModel, handleColumnVisibilityChange } = useColumnVisibility("work-packet-cases-grid");

  return (
    <ThemeProvider theme={theme}>
      <CommandCenter caseType={caseType} workPacketType={WorkPacketType.SHORTAGES} />
      <Box padding={3} display="flex" gap="12px" flexDirection="column">
        <CaseTypeSelector value="escalations" onChange={_ => {}} showEscalations showSettlements />
        <Tabs value={currentTab} onChange={(_event, tab: ViewTab) => updateTab(tab)} sx={{ paddingBlock: 3.5 }}>
          <Tab value={ViewTab.AllCases} label={ViewTab.AllCases} />
          <Tab value={ViewTab.NewCases} label={ViewTab.NewCases} />
          <Tab value={ViewTab.MyCases} label={ViewTab.MyCases} />
        </Tabs>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" gap="12px">
            <Button
              startIcon={<FilterList />}
              variant="outlined"
              onClick={toggleFilters}
              className={showFilters ? "" : "active"}
            >
              Filters
            </Button>
          </Box>
        </Box>
        <Collapse in={showFilters}>
          <Formik initialValues={currentFilters} onSubmit={applyFilters} enableReinitialize>
            <ShortageCaseFilterForm />
          </Formik>
        </Collapse>
        <Box>
          {query.isError ? (
            <span>Failed to load list of cases.</span>
          ) : (
            <DataGridPro
              columns={shortageCasesColumns}
              rows={query.data?.cases ?? EMPTY_ARRAY}
              getRowId={row => row.chargeGuardCaseId}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              rowCount={query.data?.totalRecords}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              filterMode="server"
              sortingMode="server"
              onSortModelChange={setSortModel}
              loading={query.isPending}
              rowHeight={70}
              autoHeight
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
