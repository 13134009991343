import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getVendorList } from "src/queries/vendors";

export const useVendorOptions = () => {
  const { data: vendorList } = useQuery({
    queryKey: ["vendors"],
    queryFn: getVendorList,
    staleTime: 30000,
  });

  const vendorOptions = useMemo(
    () => (vendorList ?? []).map(vendor => ({ value: vendor.VENDOR_NAME, title: vendor.VENDOR_NAME })),
    [vendorList],
  );

  return vendorOptions;
};
