import Event from "@mui/icons-material/Event";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { FieldProps } from "formik";
import { type FC } from "react";
import { EMPTY_DATE_RANGE, parseDateRange, stringifyDateRange } from "src/utils/date-range";

export interface StringDateRangePickerFieldProps extends FieldProps {
  label: string;
  disabled?: boolean;
  calendars?: 1 | 2 | 3;
}

/** DateRangePickerField component integrates Material-UI's DateRangePicker component with Formik. */
const StringDateRangePickerField: FC<StringDateRangePickerFieldProps> = ({
  field,
  form,
  label,
  disabled,
  calendars = 1,
}) => {
  const value = parseDateRange(field.value) ?? EMPTY_DATE_RANGE;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        label={label}
        value={value}
        calendars={calendars}
        onChange={value => {
          form.setFieldValue(field.name, stringifyDateRange(value));
        }}
        format="YYYY-MM-DD"
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{ textField: { InputProps: { startAdornment: <Event />}} }}
        sx={{
          width: "100%",
          "& .MuiInputBase-root input": {
            padding: "8.5px 14px 8.5px 0",
          },
          "& .MuiInputAdornment-root": {
            marginRight: 0,
          },
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default StringDateRangePickerField;
