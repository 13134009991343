import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { type FC } from "react";
import { type ChargebackWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";

export const DetailsSecondDispute: FC = () => {
  const { initialValues: packet } = useFormikContext<ChargebackWorkPacket>();
  const issueDate = packet.secondDisputeCreatedAt;
  if (!issueDate) return null;

  return (
    <FormFieldsGroup
      title="Second Dispute"
      withDivider
      id="details-second-dispute"
    >
      <TextField
        value={issueDate}
        variant="outlined"
        placeholder="Second Dispute - Chargeback Issue Date"
        size="small"
        label="Second Dispute - Chargeback Issue Date"
        disabled
      />
    </FormFieldsGroup>
  );
};
