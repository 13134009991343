import { Box } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import { AutocompleteField, CheckboxField, SelectField, StringDateRangePickerField } from "src/components/UI/Form";
import { WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketType } from "../../WorkPacketType";
import { WorkPacketView } from "../../WorkPacketView";
import { useWorkPacketsContext } from "../../WorkPacketsContext";
import { useUserNickname } from "../../useUserNickname";
import { useUserOptions } from "../../useUserOptions";
import { filterableWorkPacketActions, staticFilterOptions } from "../helpers/getStaticFilterOptions";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";
import { useAccountOptions } from "./hooks/useAccountOptions";
import { useAuditAccountOptions } from "./hooks/useAuditAccountOptions";
import { useVendorOptions } from "./hooks/userVendorOptions";

const recoveryStreamOptions = [
  { value: "PPV", title: "PPV" },
  { value: "PQV", title: "PQV" },
];

export const ShortageFiltersForm: FC = () => {
  const { setFieldValue } = useFormikContext();
  const { currentView, currentWorkPacketType } = useWorkPacketsContext();
  const userNickname = useUserNickname();

  const userOptions = useUserOptions();
  const accountOptions = useAccountOptions();
  const vendorOptions = useVendorOptions();
  const auditAccountOptions = useAuditAccountOptions();

  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr) 1.5fr",
        },
        gap: 2,
        rowGap: 3,
      }}
    >
      <Field
        name={WorkPacketFilter.VendorName}
        component={AutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Vendor Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={AutocompleteField}
        label="Store Name"
        options={accountOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Store Name"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.PacketStage}
        component={SelectField}
        label="Packet Stage"
        options={staticFilterOptions[WorkPacketFilter.PacketStage]}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={filterableWorkPacketActions[WorkPacketType.SHORTAGES]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={WorkPacketFilter.ManualFilingUser}
        component={AutocompleteField}
        label="Manual Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.RecoveryStream}
        component={AutocompleteField}
        label="Recovery Stream"
        options={recoveryStreamOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(currentView, userNickname)}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disabled={currentView !== WorkPacketView.AllPackets}
      />

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box flexGrow={1}>
          <Field
            name={WorkPacketFilter.DisputeCreatedAt}
            component={StringDateRangePickerField}
            label="Dispute - Created At"
          />
        </Box>
        <Field type="checkbox" name={WorkPacketFilter.RedFlags} component={CheckboxField} label="Red flags" />
      </Box>
    </Box>
  );
};
