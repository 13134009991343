import { createContext } from "react";

export interface PendingDialogAction {
  description: string;
}

export interface DialogActionStore {
  setPendingDialogAction(key: string, action: PendingDialogAction): void;
  clearPendingDialogAction(key: string): void;
}

export const DialogActionContext = createContext<DialogActionStore>(undefined!);
