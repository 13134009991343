import HighlightOff from "@mui/icons-material/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useMutation } from "@tanstack/react-query";
import prettyBytes from "pretty-bytes";
import { useCallback, useContext, useEffect, useRef, useState, type FC } from "react";
import { createPortal } from "react-dom";
import * as WorkPacketsApi from "src/pages/UserDashboard/WorkPackets/api/workPacketsAPI";
import {
  FileUploadContext,
  type FileUpload,
  type FileUploadStore,
} from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/Summary/FileUploadContext";
import { type ServerModelAttachment } from "src/types/work-packets";
import css from "./FileUploadBox.module.css";

type FileUploadItemProps = Pick<FileUploadStore, "removeFileUpload"> & {
  upload: FileUpload;
};

const getStatusDisplay = (upload: FileUpload) => {
  if (upload.status === "pending") return "to be uploaded";
  if (upload.status === "uploaded") return "uploaded successfully";
  if (upload.status === "failed") return `failed to upload, ${upload.error}`;
  if (upload.status === "uploading") {
    let statusDisplay = "uploading";
    const progress = upload.uploadProgress;
    if (progress.progress !== undefined) {
      statusDisplay += `, ${Math.trunc(progress.progress * 100)}%`;
    }
    if (progress.estimated !== undefined) {
      const seconds = Math.ceil(progress.estimated);
      statusDisplay += `, ${seconds} ${seconds === 1 ? "second" : "seconds"} left`;
    }
    return statusDisplay;
  }
  return "";
};

const FileUploadItem: FC<FileUploadItemProps> = ({ upload, removeFileUpload }) => {
  const statusDisplay = getStatusDisplay(upload);
  return (
    <div className={css.fileListItem}>
      <div className={css.fileIcon}>
        <InsertDriveFile />
      </div>
      <div className={css.fileDetails}>
        <span>{upload.file.name}</span>
        <span>
          {prettyBytes(upload.file.size)} – {statusDisplay}
        </span>
      </div>
      <div className={css.fileActions}>
        <button type="button" onClick={() => removeFileUpload(upload.file.name)}>
          <HighlightOff />
        </button>
      </div>
    </div>
  );
};

interface ExistingAttachmentItemProps {
  attachment: ServerModelAttachment;
}

const ExistingAttachmentItem: FC<ExistingAttachmentItemProps> = ({ attachment }) => {
  const filenameParts = attachment.ATTACHMENT.FILE_NAME.split(".");
  const fileType = filenameParts.length > 1 ? filenameParts.pop()?.toUpperCase() : undefined;

  const mutation = useMutation({
    mutationKey: ["work-packet-attachment-get", attachment.ID],
    mutationFn: WorkPacketsApi.getWorkPacketAttachmentLink,
    onSuccess: link => window.open(link, "_blank"),
  });

  const isOpening = mutation.isPending;

  return (
    <button
      type="button"
      className={css.fileListItem}
      onClick={() => mutation.mutateAsync(attachment.ATTACHMENT_ID)}
      {...(isOpening && { style: { opacity: 0.5 }, inert: "" })}
    >
      <div className={css.fileIcon}>
        <InsertDriveFile />
      </div>
      <div className={css.fileDetails}>
        <span>{attachment.ATTACHMENT.FILE_NAME}</span>
        {isOpening ? <span>opening...</span> : <span>{fileType ? `${fileType} file` : "File"}</span>}
      </div>
    </button>
  );
};

type FullscreenDropzoneProps = Pick<FileUploadStore, "enqueueFileUpload"> & {
  isVisible: boolean;
  setVisibility: (value: boolean) => void;
};

const FullscreenDropzone: FC<FullscreenDropzoneProps> = ({ enqueueFileUpload, isVisible, setVisibility }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const displayDropzone = useCallback(
    (event: DragEvent) => {
      if (event.dataTransfer && Array.from(event.dataTransfer.items).some(item => item.kind === "file")) {
        setVisibility(true);
      }
    },
    [setVisibility],
  );
  const hideDropzone = useCallback(() => void setVisibility(false), [setVisibility]);

  const [hoverItemCount, setHoverItemCount] = useState(0);

  const handleDrop = useCallback(
    (event: DragEvent) => {
      hideDropzone();
      if (!event.dataTransfer?.files.length) return;
      event.preventDefault();
      enqueueFileUpload(Array.from(event.dataTransfer.files));
    },
    [enqueueFileUpload, hideDropzone],
  );

  const checkDrag = useCallback((event: DragEvent) => {
    if (!event.dataTransfer?.items.length) return;
    setHoverItemCount(event.dataTransfer.items.length);
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  }, []);

  useEffect(() => {
    window.addEventListener("dragenter", displayDropzone);
    return () => void window.removeEventListener("dragenter", displayDropzone);
  }, [displayDropzone]);

  useEffect(() => {
    if (!elementRef.current) return;
    const el = elementRef.current;
    el.addEventListener("dragenter", checkDrag);
    el.addEventListener("dragover", checkDrag);
    el.addEventListener("dragleave", hideDropzone);
    return () => {
      el.removeEventListener("dragenter", checkDrag);
      el.removeEventListener("dragover", checkDrag);
      el.removeEventListener("dragleave", hideDropzone);
    };
  }, [checkDrag, hideDropzone]);

  useEffect(() => {
    if (!elementRef.current) return;
    const el = elementRef.current;
    el.addEventListener("drop", handleDrop);
    return () => void el.removeEventListener("drop", handleDrop);
  }, [handleDrop]);

  return (
    <div ref={elementRef} className={css.fullscreenDropzone} style={{ display: isVisible ? "grid" : "none" }}>
      <div className={css.fullscreenDropzoneInstructions}>
        <span>
          Drop {hoverItemCount === 1 ? "that file" : `those ${hoverItemCount} files`} here to attach them to this work
          packet
        </span>
      </div>
    </div>
  );
};

export const FileUploadBox: FC = () => {
  const { existingFiles, isLoadingExistingFiles, isUploading, fileUploads, enqueueFileUpload, removeFileUpload } =
    useContext(FileUploadContext);

  const onInputChange = useCallback(
    function onInputChange(this: HTMLInputElement) {
      if (!this.files) return;
      enqueueFileUpload(Array.from(this.files));
    },
    [enqueueFileUpload],
  );

  const onFilePickButtonClick = useCallback(() => {
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.multiple = true;
    inputElement.accept = ".doc, .docx, .xls, .xlsx, .xlsv, .csv, .png, .jpg, .jpeg";
    inputElement.addEventListener("change", onInputChange);
    inputElement.click();
  }, [onInputChange]);

  const filePickButtonRef = useRef<HTMLDivElement>(null);
  const [isFullscreenDropzoneVisible, setFullscreenDropzoneVisibility] = useState(false);

  useEffect(() => {
    if (isFullscreenDropzoneVisible && filePickButtonRef.current) {
      filePickButtonRef.current.scrollIntoView({ block: "nearest", inline: "nearest", behavior: "smooth" });
    }
  }, [filePickButtonRef, isFullscreenDropzoneVisible]);

  if (isLoadingExistingFiles) {
    return <div>Loading attachments...</div>;
  }

  return (
    <div
      className={css.fileUploadSection}
      ref={filePickButtonRef}
      {...(isUploading && { inert: "", style: { opacity: 0.5 } })}
    >
      <button
        type="button"
        className={css.filePickButton}
        onClick={onFilePickButtonClick}
        disabled={isLoadingExistingFiles}
      >
        <div className={css.iconOuter}>
          <div className={css.iconInner}>
            <UploadFileIcon width={32} />
          </div>
        </div>
        <p>
          <span className={css.bold}>Click to upload</span> or drag and drop
        </p>
        <p>DOC(X), XLS(X), CSV, PDF, PNG, JPG</p>
        {isLoadingExistingFiles && <p>Loading!</p>}
      </button>
      {existingFiles.map(attachment => (
        <ExistingAttachmentItem attachment={attachment} key={attachment.ID} />
      ))}
      {fileUploads.map(fileUpload => (
        <FileUploadItem upload={fileUpload} removeFileUpload={removeFileUpload} key={fileUpload.file.name} />
      ))}
      {fileUploads.length > 0 && (
        <div className={css.fileListHint}>
          <div className={css.fileIcon}>
            <InfoIcon color="error" />
          </div>
          <div className={css.fileDetails}>
            <span>
              <b>Save Changes</b> to upload{" "}
              {fileUploads.length === 1 ? "this file" : `these ${fileUploads.length} files`}
            </span>
          </div>
        </div>
      )}
      {createPortal(
        <FullscreenDropzone
          enqueueFileUpload={enqueueFileUpload}
          isVisible={isFullscreenDropzoneVisible}
          setVisibility={setFullscreenDropzoneVisibility}
        />,
        document.body,
      )}
    </div>
  );
};
