import { type GridRenderEditCellParams } from "@mui/x-data-grid";
import { type FC } from "react";
import { type WorkPacket } from "src/types/work-packets";
import { useUserOptions } from "../../useUserOptions";
import EditableAutocompleteCell from "../EditableAutocompleteCell";

export const CurrentOwnerEdit: FC<GridRenderEditCellParams<WorkPacket>> = props => {
  const options = useUserOptions();
  return <EditableAutocompleteCell {...props} options={options} />;
};
