import { Tab, Tabs } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import theme from "src/styles/theme";
import { CaseCorrespondence } from "./tabs/CaseCorrespondence/CaseCorrespondence";
import { CaseDetails } from "./tabs/CaseDetails/CaseDetails";
import { CaseChangeLog } from "./tabs/CaseChangeLog/CaseChangeLog";
import { WorkPacketType } from "../WorkPacketType";


enum CaseTab {
  Details = "details",
  Comments = "comments",
  Changelog = "changelog",
  Correspondence = "correspondence",
}

const getCasePageUrl = (caseType: string, caseId: string, caseTab: CaseTab) =>
  `/user-dashboard/work-packets/shortages/${caseType}/${caseId}/${caseTab}`;

export const SingleCasePage: FC = () => {
  const navigate = useNavigate();
  const { caseType, caseId, tab: urlTab } = useParams<"caseType" | "caseId" | "tab">();
  const currentTab = useMemo(() => {
    if (!Object.values(CaseTab).includes(urlTab as CaseTab)) return CaseTab.Details;
    return urlTab as CaseTab;
  }, [urlTab]);

  const paramsAreValid = caseType && caseId;

  const setCurrentTab = useCallback(
    (caseTab: CaseTab) => {
      if (!paramsAreValid) return;
      navigate(getCasePageUrl(caseType, caseId, caseTab));
    },
    [caseId, caseType, navigate, paramsAreValid],
  );

  useEffect(() => {
    if (paramsAreValid && urlTab !== currentTab) {
      navigate(getCasePageUrl(caseType, caseId, CaseTab.Details), { replace: true });
    }
  }, [caseId, caseType, currentTab, navigate, paramsAreValid, urlTab]);

  if (!paramsAreValid) return null;

  return (
    <ThemeProvider theme={theme}>
      <Tabs value={currentTab} onChange={(_event, tab: CaseTab) => setCurrentTab(tab)} sx={{ paddingTop: 3.5 }}>
        <Tab value={CaseTab.Details} label="Details" />
        <Tab value={CaseTab.Comments} label="Comments" />
        <Tab value={CaseTab.Changelog} label="Change Log" />
        <Tab value={CaseTab.Correspondence} label="Correspondence" />
      </Tabs>
      {currentTab === CaseTab.Details ? (
        <CaseDetails caseId={caseId} />
      ) : currentTab === CaseTab.Comments ? (
        <CaseDetails caseId={caseId} />
      ) : currentTab === CaseTab.Changelog ? (
        <CaseChangeLog caseId={caseId} caseType={WorkPacketType.SHORTAGES} />
      ) : currentTab === CaseTab.Correspondence ? (
        <CaseCorrespondence caseId={caseId} />
      ) : null}
    </ThemeProvider>
  );
};
